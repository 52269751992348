<template>
    <div>
        <v-form
        ref="form"
        v-model="valid"
        v-on:submit.prevent
        >
            <v-snackbar
            :color="color"
            :timeout="3000"
            v-model="alert"
            top
            >
              <v-row justify="space-between">
                {{text}}
                <v-icon color="white" @click="alert = false">mdi-close</v-icon>
              </v-row>
            </v-snackbar>
            <v-row no-gutters>
                <city type="carPickup" />
                <city type="carDrop" class="d-block d-lg-none"/>

                <v-col cols="12" sm="6" md="4" lg="2">
                    <datePicker type="Pickup Date" />
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="2">
                    <timePicker type="Pickup Time" />
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="2">
                    <datePicker type="Drop Date" />
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="2">
                    <timePicker type="Drop Time" />
                </v-col>
                <v-col cols="12" :md="dropOff ? 12 : 4" lg="2">
                    <v-btn
                    type="submit"
                    @click="submit"
                    tile
                    block
                    color="secondary"
                    height="52"
                    dark
                    class="mr-1 mt-1"
                    :loading="loading"
                    >
                    Search
                    </v-btn>
                </v-col>
                <city type="carDropLarge" class="d-none d-lg-flex"/>
            </v-row>
        </v-form>
    </div>
</template>

<script>
import { carSearch, headersNoAuth } from '../links'
import city from './citiesAutocomplete.vue'
import datePicker from './datePicker.vue'
import timePicker from './timePicker.vue'

export default {
  components: {
    datePicker,
    timePicker,
    city
  },
  data () {
    return {
      valid: true,
      color: '',
      alert: false,
      text: '',
      loading: false,
      dropOff: false
    }
  },
  methods: {
    submit () {
      this.$emit('startLoad')
      this.loading = true
      this.$refs.form.validate()
      if (this.valid) {
        const store = this.$store.state.carInfo
        if (new Date(store.pickup).getTime() > new Date().getTime()) {
          if (new Date(store.pickup).getTime() < new Date(store.drop).getTime()) {
            if (store.pickupLocation) {
              let term = `pickUpDate=${store.pickup}&pickUpTime=${store.pickupTime}&returnDate=${store.drop}&returnTime=${store.dropTime}&pickUpLocation=${store.pickupLocation.Code}&sortOrder=ASC`
              if (store.dropLocation) term += `&dropOffLocation=${store.dropLocation.Code}`
              this.$http.get(carSearch(term), { headers: headersNoAuth() }).then(response => {
                this.$emit('endLoad')
                if (response.status === 500 || response.body.status === false) {
                  this.alert = true
                  this.color = 'error'
                  this.text = 'No cars match your search'
                  this.loading = false
                } else {
                  this.loading = false
                  this.$store.dispatch('setCars', response.body.data)
                  this.$store.dispatch('setCarsTimeOut', false)
                  if (this.$store.state.carsSearchTimeOutFunction !== null) this.$store.dispatch('clearCarsSearchTimeOutFunction')
                  if (this.$store.state.carsTimeOutFunction) this.$store.dispatch('clearCarsTimeOutFunction')
                  this.$store.dispatch('setCarsSearchTimeOutFunction', setTimeout(() => {
                    this.$store.dispatch('setCarsTimeOut', true)
                  }, 20 * 60 * 1000)
                  )
                  this.$store.dispatch('setShowCarResults', true)
                  localStorage.setItem('carsSearchTerm', term)
                  this.$emit('scrollNow')
                }
              })
            } else {
              this.alert = true
              this.color = 'error'
              this.text = 'Please choose pickp up location'
              this.loading = false
              this.$emit('endLoad')
            }
          } else {
            this.alert = true
            this.color = 'error'
            this.text = 'Please choose drop date after the pickp date'
            this.loading = false
            this.$emit('endLoad')
          }
        } else {
          this.alert = true
          this.color = 'error'
          this.text = 'Please choose pickup date after today date'
          this.loading = false
          this.$emit('endLoad')
        }
      } else {
        this.alert = true
        this.color = 'warning'
        this.text = 'Please fill all fields'
        this.loading = false
        this.$emit('endLoad')
      }
    }
  }
}
</script>
