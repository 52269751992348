<template>
  <div>
    <v-container fluid>
    <v-snackbar
    color="error"
    top
    multiLine
    v-model="errorBar"
    :timeout="3000"
    >
      Please Fill All Fields !
      <v-btn
        dark
        icon
        @click="errorBar = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-snackbar
    color="warning"
    top
    multiLine
    v-model="warningBar"
    :timeout="3000"
    >
      Sorry, we couldn't find any flights that match your search
      <v-btn
        dark
        icon
        @click="warningBar = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <!-- radio group  -->
    <v-form ref="form">
    <v-radio-group row color="white" v-model="flightType">
      <v-radio color="orange" value="round">
        <template v-slot:label>
          <span class="white--text font-weight-medium" style="letter-spacing: 1.5px;">Round Trip</span>
        </template>
      </v-radio>
      <v-radio color="orange" value="one">
        <template v-slot:label>
          <span class="white--text font-weight-medium" style="letter-spacing: 1.5px;">One Way</span>
        </template>
      </v-radio>
      <v-radio color="orange" value="multi">
        <template v-slot:label>
          <span class="white--text font-weight-medium" style="letter-spacing: 1.5px;">Multi City</span>
        </template>
      </v-radio>
    </v-radio-group>

    <!-- inputs section -->
    <v-row no-gutters>

      <v-col cols="12" md="5">
      <!-- autocomplete components of one way and round trips -->
        <v-row no-gutters>
          <cities type="Departure"></cities>
          <cities type="Arrival" @setSecondDeparture="setSecondDeparture"></cities>
        </v-row>
      <!-- datepickers components for round trips -->
        <v-row no-gutters class="d-flex d-md-none">
          <v-col cols="12" class="py-0 my-0">
            <picker v-if="flightType === 'round'" type="dep-round" date-placeholder="Trip Date" ></picker>
            <picker v-else type="Departure Date" date-placeholder="Departure Date" ></picker>
          </v-col>
        </v-row>
      <!-- second autocomplete components for multi -->
        <v-row no-gutters v-if="flightType === 'multi'">
          <cities type="Second Departure" :getSecondDeparture="secondDeparture"></cities>
          <cities type="Second Arrival" @setThirdDeparture="setThirdDeparture"></cities>
        </v-row>
      <!-- second datepickers component for multi -->
        <v-row no-gutters v-if="flightType === 'multi'" class="d-flex d-md-none">
          <v-col cols="12" class="py-0 my-0">
            <picker type="Second Departure Date"></picker>
          </v-col>
        </v-row>
      <!-- third autocomplete components for multi -->
        <v-row no-gutters v-if="flightType === 'multi' && segments">
          <cities type="Third Departure" :getThirdDeparture="thirdDeparture"></cities>
          <cities type="Third Arrival"></cities>
        </v-row>
      <!-- third datepickers component for multi -->
        <v-row no-gutters v-if="flightType === 'multi' && segments" class="d-flex d-md-none">
          <v-col cols="12" class="py-0 my-0">
            <picker type="Third Departure Date"></picker>
          </v-col>
        </v-row>
      <!-- button for adding a travel segment -->
        <v-btn text color="secondary" v-if="flightType === 'multi' && !segments" class="d-block mt-3 d-md-none btn" @click="segments = !segments">
          <v-icon left>mdi-plus-circle</v-icon>
          Add a segment
        </v-btn>
      <!-- button for removing a travel segment -->
        <v-btn text color="secondary" v-if="flightType === 'multi' && segments" class="d-block mt-3 d-md-none btn" @click="removeSegment">
          <v-icon left>mdi-close-circle</v-icon>
          Remove this segment
        </v-btn>
      </v-col>

      <!-- date picker component on medium and up screens -->
      <v-col cols="12" md="3">
        <v-row no-gutters class="d-none d-md-flex">
          <v-col cols="12" md="12" class="py-0 my-0">
            <picker type="dep-round" v-if="flightType === 'round'" date-placeholder="Trip Date"></picker>
            <picker v-else type="Departure Date" date-placeholder="Departure Date" ></picker>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="flightType === 'multi'" class="d-none d-md-flex" style="position: relative;">
          <v-col cols="12" :md="segments === true ? 12 : 12" class="py-0 my-0">
            <picker type="Second Departure Date"></picker>
          </v-col>
          <v-col cols="12" md="1">
            <v-btn icon x-large color="secondary lighten-1" v-if="!segments" @click="segments = !segments" style="position: absolute; top: 1px; left: 100%;"><v-icon>mdi-plus-circle</v-icon></v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="flightType === 'multi' && segments" class="d-none d-md-flex" style="position: relative;">
          <v-col cols="12" :md="segments === true ? 12 : 12" class="py-0 my-0">
            <picker type="Third Departure Date"></picker>
          </v-col>
          <v-col cols="12" md="1">
            <v-btn icon x-large color="red lighten-1" @click="removeSegment" style="position: absolute; top: 1px; left: 100%;"><v-icon>mdi-close-circle</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-col>

      <!-- passengers info component -->
      <v-col cols="12" :md="3" class="py-0 my-0" style="height: 73px">
        <passengers/>
      </v-col>

      <!-- airlines small -->
      <cities v-if="$vuetify.breakpoint.smAndDown" type="airline"></cities>
      <v-switch
      v-if="$vuetify.breakpoint.smAndDown"
      v-model="directFlights"
      inset
      label="Direct Flights"
      class="mx-5"
      color="success"
      ></v-switch>

      <!-- submit button -->
      <v-col cols="12" md="1">
        <v-btn
        tile
        class="white--text mt-1 search"
        block
        color="orange"
        height="52"
        :loading="loading"
        @click="submit"
        >
        search
        </v-btn>
      </v-col>

      <!-- airlines medium and up -->
      <v-row v-if="$vuetify.breakpoint.mdAndUp">
        <cities type="airline"></cities>
        <v-col cols="12" md="3">
          <v-switch
          v-model="directFlights"
          inset
          class="mx-5 white--text"
          color="success"
          >
            <template v-slot:label>
            <span class="white--text font-weight-medium" style="letter-spacing: 1.5px;">Direct Flights</span>
          </template>
          </v-switch>
        </v-col>
      </v-row>
    </v-row>
    </v-form>
    </v-container>
  </div>
</template>

<script>
import cities from './citiesAutocomplete.vue'
import picker from './datePicker.vue'
import passengers from './passengersInfo'
import { searchFlights, headersNoAuth } from '../links'

export default {
  components: {
    cities,
    picker,
    passengers
  },
  data () {
    return {
      flightType: 'round',
      today: new Date().toISOString().substr(0, 10),
      segments: true,
      errorBar: false,
      warningBar: false,
      loading: false,
      secondDeparture: null,
      thirdDeparture: null,
      directFlights: false
    }
  },
  computed: {
    tomorrow () {
      const tomorrow = new Date(this.today)
      tomorrow.setDate(tomorrow.getDate() + 1)
      return tomorrow.toISOString().slice(0, 10)
    }
  },
  methods: {
    setSecondDeparture (value) {
      this.secondDeparture = value
    },
    setThirdDeparture (value) {
      this.thirdDeparture = value
    },
    removeSegment () {
      this.segments = !this.segments
      this.$store.dispatch('removeSegment')
    },
    submit () {
      this.$emit('startLoad')
      const searchStore = this.$store.state
      console.log(searchStore.passengers)
      const searchParameters = {
        tripType: this.flightType
      }
      this.$store.dispatch('setType', this.flightType)
      let data = ''
      let string = ''
      if (new Date(searchStore.flightDates.departureDates[0]).getTime() > new Date().getTime()) {
        if (new Date(searchStore.flightDates.departureDates[0]).getTime() < new Date(searchStore.flightDates.arrivalDate).getTime() || this.flightType !== 'round') {
          switch (this.flightType) {
            case 'round':
              if (searchStore.airports.departureAirports[0] &&
                  searchStore.airports.arrivalAirports[0] &&
                  searchStore.flightDates.departureDates[0] &&
                  searchStore.flightDates.arrivalDate
              ) {
                searchParameters.origin = searchStore.airports.departureAirports[0].Code
                searchParameters.destination = searchStore.airports.arrivalAirports[0].Code
                searchParameters.originType = searchStore.airports.departureAirports[0].Type
                searchParameters.destinationType = searchStore.airports.arrivalAirports[0].Type
                searchParameters.departureDate = searchStore.flightDates.departureDates[0]
                searchParameters.arrivalDate = searchStore.flightDates.arrivalDate
                searchParameters.classType = searchStore.passengers.classType
                searchParameters.adults = searchStore.passengers.adults
                searchParameters.children = searchStore.passengers.children
                searchParameters.infants = searchStore.passengers.infants
                searchParameters.directFlights = this.directFlights ? 1 : 0
                if (searchStore.preferredAirline) searchParameters.preferredAirline = searchStore.preferredAirline.code
                data = Object.entries(searchParameters).map(
                  ([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
                ).join('&')
                this.request(data)
              } else {
                this.errorBar = true
              }
              break
            case 'one':
              if (searchStore.airports.departureAirports[0] &&
                  searchStore.airports.arrivalAirports[0] &&
                  searchStore.flightDates.departureDates[0]
              ) {
                searchParameters.origin = searchStore.airports.departureAirports[0].Code
                searchParameters.destination = searchStore.airports.arrivalAirports[0].Code
                searchParameters.originType = searchStore.airports.departureAirports[0].Type
                searchParameters.destinationType = searchStore.airports.arrivalAirports[0].Type
                searchParameters.departureDate = searchStore.flightDates.departureDates[0]
                searchParameters.classType = searchStore.passengers.classType
                searchParameters.adults = searchStore.passengers.adults
                searchParameters.children = searchStore.passengers.children
                searchParameters.infants = searchStore.passengers.infants
                searchParameters.directFlights = this.directFlights ? 1 : 0
                if (searchStore.preferredAirline) searchParameters.preferredAirline = searchStore.preferredAirline.code
                data = Object.entries(searchParameters).map(
                  ([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
                ).join('&')
                this.request(data)
              } else {
                this.errorBar = true
              }
              break
            case 'multi':
              if (searchStore.airports.departureAirports[0] &&
                  searchStore.airports.departureAirports[1] &&
                  searchStore.airports.arrivalAirports[0] &&
                  searchStore.airports.arrivalAirports[1] &&
                  searchStore.flightDates.departureDates[0] &&
                  searchStore.flightDates.departureDates[1]
              ) {
                searchParameters.origin = searchStore.airports.departureAirports
                searchParameters.destination = searchStore.airports.arrivalAirports
                searchParameters.departureDate = searchStore.flightDates.departureDates
                searchParameters.classType = searchStore.passengers.classType
                searchParameters.adults = searchStore.passengers.adults
                searchParameters.children = searchStore.passengers.children
                searchParameters.infants = searchStore.passengers.infants
                string = 'tripType=multi'
                for (let i = 0; i < searchParameters.origin.length; i++) {
                  if (i === 2 && searchParameters.origin[2] === null) {
                    continue
                  }
                  string += `&origin[]=${searchParameters.origin[i].Code}&destination[]=${searchParameters.destination[i].Code}&originType[]=${searchParameters.origin[i].Type}&destinationType[]=${searchParameters.destination[i].Type}&departureDate[]=${searchParameters.departureDate[i]}`
                }
                string += `&classType=${searchParameters.classType}&adults=${searchParameters.adults}&children=${searchParameters.children}&infants=${searchParameters.infants}`
                string += `&directFlights=${this.directFlights ? 1 : 0}`
                if (searchStore.preferredAirline) string += `&preferredAirline${searchStore.preferredAirline.code}`
                this.request(string)
              } else {
                this.errorBar = true
              }
              break
            default:
              break
          }
        } else {
          this.snackbar = true
          this.color = 'error'
          this.text = 'Please choose departure date before the arrival date you chose'
          this.$emit('endLoad')
        }
      } else {
        this.snackbar = true
        this.color = 'error'
        this.text = 'Please choose departure date starting after today'
        this.$emit('endLoad')
      }
    },
    request (data) {
      this.loading = true
      this.$store.dispatch('removetFlightResults')
      this.$store.dispatch('setShowFlightResults', false)
      this.$store.dispatch('setDirectFlights', this.directFlights)
      this.$http.get(searchFlights(data), { headers: headersNoAuth() }).then(response => response.json()).then(response => {
        this.$emit('endLoad')
        // if (response.status === false) {
        //   this.warningBar = true
        //   this.loading = false
        // } else {
        this.$store.dispatch('setFlightsTimeOut', false)
        if (this.$store.state.flightsSearchTimeOutFunction !== null) this.$store.dispatch('clearFlightsSearchTimeoutFunction')
        if (this.$store.state.flightsTimeOutFunction !== null) this.$store.dispatch('clearFlightsTimeoutFunction')
        this.$store.dispatch('setFlightsSearchTimeoutFunction', setTimeout(() => {
          this.$store.dispatch('setFlightsTimeOut', true)
        }, 20 * 60 * 1000)
        )
        this.$store.dispatch('setFlightResults', response.data)
        this.loading = false
        localStorage.setItem('alternateSearchTerm', data)
        this.$store.dispatch('setShowFlightResults', true)
        this.$emit('scrollNow')
        this.$emit('addFlights')
        // }
      }, () => {
        this.loading = false
        this.$emit('endLoad')
        this.warningBar = true
      })
    }
  },
  created () {
    this.$store.state.flightType === '' ? this.flightType = 'round' : this.flightType = this.$store.state.flightType
  }
}
</script>

<style scoped>
  .search {
    border-radius: 5px;
    padding-top: 5px;
  }
  .btn {
    transform: translateY(-15px);
  }
</style>
