<template>
    <v-card class="pa-1" id="filter" :height="height" scrollable>
        <h5 color="secondary">Price</h5>
        <v-row justify="space-between">
            <v-subheader color="secondary">${{min}}</v-subheader>
            <v-subheader color="secondary">${{max}}</v-subheader>
        </v-row>
        <v-range-slider
        v-model="prices"
        :min="min"
        :max="max"
        color="secondary"
        hide-details
        class="align-center"
        thumb-label
        @change="filterate()"
        >
        </v-range-slider>

        <v-divider></v-divider>
        <h5 color="secondary">Stars</h5>
        <v-rating
        v-model="stars"
        length="5"
        empty-icon="mdi-star-outline"
        full-icon="mdi-star"
        hover
        size="15"
        color="warning"
        background-color="warning"
        @input="filterate()"
        ></v-rating>
        <v-btn text color="blue" @click="allHotels('stars')">All Stars <v-icon right>mdi-chevron-right</v-icon></v-btn>
        <v-divider></v-divider>
        <h5 color="secondary">Rates</h5>
        <v-btn text color="blue" @click="allHotels('rates')">All Rates <v-icon right>mdi-chevron-right</v-icon></v-btn>
        <v-btn text v-for="(rate, i) in rates" :key="i" class="my-5" block @click="selectedRate = rate.tripAdviserRating; filterate();">
            <v-row justify="space-between">
                <v-avatar color="indigo" size="20">
                    <span class="white--text caption">{{rate.tripAdviserRating}}</span>
                </v-avatar>
                <v-spacer></v-spacer>
                <div>
                    {{rate.numberOfHotels}} Hotels
                    <v-icon>mdi-chevron-right</v-icon>
                </div>
            </v-row>
        </v-btn>
    </v-card>
</template>

<script>
export default {
  data () {
    return {
      hotels: [],
      rates: [],
      selectedRate: 0,
      prices: [],
      min: 0,
      max: 0,
      stars: 0,
      height: 0
    }
  },
  methods: {
    // watchScroll () {
    //   const el = document.getElementById('filter')
    //   window.pageYOffset > 500 ? el.style.position = 'fixed' : el.style.position = 'relative'
    //   if (window.pageYOffset > 500) el.style.top = '20px'
    // },
    filterate () {
      const filtered = []
      this.hotels.forEach(hotel => {
        if (this.prices[0] <= hotel.pricingInfo.totalPrice && hotel.pricingInfo.totalPrice <= this.prices[1] && hotel.stars === (this.stars > 0 ? this.stars : hotel.stars) && hotel.TripAdviserRating === (this.selectedRate > 0 ? this.selectedRate : hotel.TripAdviserRating)) {
          filtered.push(hotel)
        }
      })
      this.$emit('changed', filtered)
    },
    allHotels (type) {
      const filtered = []
      if (type === 'rates') {
        this.selectedRate = 0
        this.hotels.forEach(hotel => {
          if (this.prices[0] <= hotel.pricingInfo.totalPrice <= this.prices[1] && hotel.TripAdviserRating === (this.selectedRate > 0 ? this.selectedRate : hotel.TripAdviserRating)) {
            filtered.push(hotel)
          }
        })
        this.$emit('changed', filtered)
      } else {
        this.stars = 0
        this.hotels.forEach(hotel => {
          if (this.prices[0] <= hotel.pricingInfo.totalPrice <= this.prices[1] && hotel.stars === (this.stars > 0 ? this.stars : hotel.stars)) {
            filtered.push(hotel)
          }
        })
        this.$emit('changed', filtered)
      }
    }
  },
  created () {
    this.min = Math.floor(this.$store.state.hotels.minPrice)
    this.max = Math.floor(this.$store.state.hotels.maxPrice)
    this.prices = [this.min, this.max]
    this.rates = this.$store.state.hotels.tripAdviserRatingFilter
    this.hotels = [...this.$store.state.hotels.Hotels]
    // window.addEventListener('scroll', this.watchScroll)
    this.height = window.innerHeight
  },
  destroyed () {
    // window.removeEventListener('scroll', this.watchScroll)
  }
}
</script>
