var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"picker-range"},[_c('v-icon',{attrs:{"color":_vm.iconColor}},[_vm._v("mdi-calendar")]),_c('date-range-picker',{ref:"picker",staticClass:"range-input",attrs:{"opens":"center","single-date-picker":(_vm.type === 'dep-round' || _vm.type === 'hotel') ? false : true,"locale-data":{
    direction: 'ltr',
    format: 'mm/dd/yyyy',
    separator: ' - ',
    applyLabel: 'Apply',
    cancelLabel: 'Cancel',
    weekLabel: 'W',
    customRangeLabel: 'Custom Range',
    daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    firstDay: 1
  },"minDate":_vm.tomorrow,"autoApply":true,"ranges":false},on:{"toggle":_vm.coloringIcon,"select":_vm.setDates},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('span',{staticClass:"body-2 text-no-wrap"},[_vm._v(_vm._s(_vm.dateRange.startDate ? new Date(_vm.dateRange.startDate).toString().substring(4, 15) : _vm.placeholder())+" "+_vm._s(_vm.dateRange.endDate && (_vm.type === 'dep-round' || _vm.type === 'hotel') ? ("- " + (new Date(_vm.dateRange.endDate).toString().substring(4, 15))) : ''))])]},proxy:true}]),model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }