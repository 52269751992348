<template>
    <div>
        <v-progress-linear
        indeterminate
        color="secondary"
        height="10"
        top
        :active="loaded"
        ></v-progress-linear>
        <v-snackbar v-model="snackbar" :color="color" top :timeout="60000">
          {{text}}
            <v-btn
            dark
            icon
            @click="snackbar = false"
            >
                <v-icon color="white">mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
        <v-container v-if="noData">
            <v-btn
              color="secondary"
              class="px-1"
              dark
              small
              fixed
              bottom
              left
              @click="$store.dispatch('setShowOneHotel', false); $store.dispatch('setShowHotelResults', true)"
              >
                <v-icon>mdi-chevron-left</v-icon>
                results
              </v-btn>
            <p class="body-1 font-weight-bold primary--text text-center">Sorry, We couldn't get any result</p>
        </v-container>
        <v-container v-if="data">
              <v-btn
              color="secondary"
              class="px-1"
              dark
              small
              fixed
              bottom
              left
              @click="$store.dispatch('setShowOneHotel', false); $store.dispatch('setShowHotelResults', true)"
              >
                <v-icon>mdi-chevron-left</v-icon>
                results
              </v-btn>
            <v-row>
                <h3 class="font-weight-bold">{{hotel.HotelName}}</h3>
                <v-rating :length="hotel.HotelStars" color="warning" readonly small :value="Number(hotel.HotelStars)" full-icon="mdi-star"></v-rating>
                <v-spacer></v-spacer>
                <div v-if="hotel.tripAdivsorRating">
                    <v-avatar color="indigo" size="36">
                        <span class="white--text headline">{{hotel.tripAdivsorRating}}</span>
                    </v-avatar>
                </div>
            </v-row>
            <v-row>
                <v-icon small class="mt-0" color="secondary">mdi-map-marker</v-icon>
                <p class="body-2 mb-0 mt-1">{{hotel.HotelAddress}},{{hotel.HotelCity}},{{hotel.HotelCountry}}</p>
                <v-btn text color="blue" small @click="openMap(hotel.map)">
                    Show on map
                </v-btn>
            </v-row>
            <v-row>
              <v-icon small class="mr-1" color="secondary">mdi-phone</v-icon>{{hotel.HotelPhone}}
              <v-icon small class="mr-1 ml-1" color="secondary">mdi-fax</v-icon>{{hotel.HotelFax}}
            </v-row>

            <v-row v-if="length > 0" justify="center">
                <v-card flat tile width="60%">
                    <v-window v-model="current">
                        <v-window-item
                        v-for="n in length"
                        :key="`card-${n}`"
                        :id="`card-${n}`"
                        >
                            <v-card
                            color="grey"
                            height="300"
                            >
                                <v-img :alt="hotel.HotelName" :src="images[n]" height="300" width="100%" class="mx-auto" @error="hideElement('card', n)"></v-img>
                            </v-card>
                        </v-window-item>
                    </v-window>

                    <v-card-actions class="justify-space-between">
                        <v-btn
                            text
                            @click="prev"
                        >
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-item-group
                            v-model="current"
                            class="text-center"
                            mandatory
                        >
                            <v-item
                            v-for="n in length"
                            :key="`btn-${n}`"
                            v-slot:default="{ active, toggle }"
                            >
                                <v-btn
                                :input-value="active"
                                @click="toggle"
                                v-if="hotel.images[n]"
                                text
                                :id="`btn-${n}`"
                                >
                                    <img :alt="hotel.HotelName" :src="hotel.images[n]" width="30" height="30" @error="hideElement('btn', n)"/>
                                </v-btn>
                            </v-item>
                        </v-item-group>
                        <v-btn
                            text
                            @click="next"
                        >
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-row>
            <h4>Most PopularFcilities</h4>
            <v-slide-group multiple show-arrows>
                <v-slide-item
                v-for="n in hotel.HotelFacilities.length"
                :key="n"
                >
                    <v-chip
                    class="ma-2"
                    outlined
                    color="secondary"
                    >
                    {{hotel.HotelFacilities[n]}}
                    </v-chip>
                </v-slide-item>
            </v-slide-group>

            <div v-if="typeof allrooms !== 'string'">
                <v-card class="my-5">
                    <v-row>
                        <v-col cols="3" style="border-right: 0.1px solid #E0E0E0;">
                        <v-list
                        two-line
                        >
                            <v-subheader class="secondary--text font-weight-bold">Select Room Type</v-subheader>
                            <v-divider></v-divider>
                            <v-list-item-group color="secondry">
                                <v-list-item
                                v-for="(room, i) in hotelRooms"
                                :key="i"
                                >
                                    <v-list-item-content @click="getAvailableRooms(room.roomIndex, room.roomCombination)">
                                        <v-list-item-title v-html="room.roomName"></v-list-item-title>
                                        <v-list-item-subtitle>${{room.rateSummary.totalFare}}, {{room.rateSummary.meal ? room.rateSummary.meal : 'No meal'}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                        </v-col>
                        <v-col cols="9">
                        <v-list
                        two-line
                        >
                            <v-subheader class="secondary--text font-weight-bold">Available Rooms</v-subheader>
                            <v-divider></v-divider>
                            <v-list-item-group v-model="room" color="secondry">
                                <v-list-item
                                v-for="(room, i) in availableRooms"
                                :key="i"
                                style="border-bottom: 0.1px solid #e0e0e0"
                                >
                                    <input type="checkbox" class="check" :id="`checkbox-${i}`" @click="selectRoom(room, i)">
                                    <v-list-item-content class="ml-2">
                                        <div>
                                            <div style="display: flex; justify-content: space-between;">
                                                <p class="font-weight-bold body-1">{{room.roomName}}</p>
                                                <div>
                                                  <p class="font-weight-bold body-1 ml-2">${{room.rateSummary.totalFare}} Total</p>
                                                  <details :value="i">
                                                    <summary style="color: blue; margin-bottom: 15px; font-size: 11px;">Show Day Rates</summary>
                                                    <p class="caption" v-for="(rate, i) in room.daysRate" :key="i">
                                                      ${{rate.baseFare}} on {{formatDate(rate.date)}}
                                                    </p>
                                                  </details>
                                                </div>
                                            </div>
                                            <div>
                                              <details :value="i+2">
                                                <summary style="color: blue; margin-bottom: 15px;">Cancelation Policies</summary>
                                                <p class="error--text">Cancelation Policies</p>
                                                <p v-if="room.cancelPolicies.lastCancellationDeadLine">Cancellation DeadLine: {{room.cancelPolicies.lastCancellationDeadLine}}</p>
                                                <p v-if="room.cancelPolicies.defaultPolicy">Hotel Policy: {{room.cancelPolicies.defaultPolicy}}</p>
                                                <div v-if="room.cancelPolicies.policies && room.cancelPolicies.policies.length > 0">
                                                    <p v-for="(policy, i) in room.cancelPolicies.policies" :key="i">From {{policy.fromDate}} to {{policy.toDate}} cancelation charge {{policy.chargeType === 'Fixed' ? '$' : '%' }}{{policy.cancellationCharge}}</p>
                                                </div>
                                              </details>
                                            </div>
                                            <div v-html="room.description"></div>
                                            <div>
                                                <p class="secondary--text">Inclusions: {{room.inclusion === "" ? 'No Inclusions' : room.inclusion}}</p>
                                                <p class="secondary--text">Meals: {{room.meal}}</p>
                                                <p class="success--text" v-if="room.promotion !== '' && room.promotion">Promotions: {{room.promotion}}</p>
                                                <details :value="i+1">
                                                  <summary style="color: blue; margin-bottom: 15px;">Show Amenities</summary>
                                                  <p class="secondary--text">Amenities: {{room.amenities ? room.amenities : 'No Amenities'}}</p>
                                                </details>
                                                <div v-if="room.supplements">
                                                  <details :value="i+3">
                                                    <summary style="color: blue; margin-bottom: 15px;">Room supplements</summary>
                                                    <p class="body-2 font-weight-bold" v-for="sup in room.supplements" :key="sup.id">
                                                      {{sup.type}}: {{sup.name}}, {{sup.chargeType}}, {{sup.price}} {{sup.currencyCode}}
                                                      <span v-if="!sup.isMandatory">
                                                        <v-btn color="blue" text @click="supplements.push({sup: sup.id, room: room.roomIndex, accept: true})" small>Add Supplement</v-btn>
                                                      </span>
                                                    </p>
                                                  </details>
                                                </div>
                                            </div>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn tile color="secondary" :disabled="selectedRooms.length < $store.state.hotelSearch.guestsInfo.length" @click="reserve()">Reserve</v-btn>
                        </v-card-actions>
                        </v-col>
                    </v-row>
                </v-card>
            </div>
            <div v-else>
                <v-alert
                border="top"
                color="error lighten-2"
                dark
                >
                {{allrooms}}
                </v-alert>
            </div>

            <div v-html="hotel.HotelDescription"></div>
            <div v-html="hotel.nearByPlaces"></div>

            <v-dialog
            v-model="dialog"
            max-width="80%"
            >
                <gmap-map
                :center="center"
                :zoom="16"
                style="width:100%;  height: 500px;"
                zoomControl
                class="mt-1"
                >
                    <gmap-marker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    @click="center=m.position"
                    :clickable="true"
                    :draggable="true"
                    ></gmap-marker>
                </gmap-map>
            </v-dialog>
            <v-dialog
            v-model="dialog1"
            max-width="80%"
            >
              <v-card>
                <v-card-title class="secondary white--text">
                  <span class="font-weight-bold headline">Call us to continue</span>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="dialog1 = false"><v-icon color="white">mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-text class="text-center">
                  <p class="font-weight-bold headline">1-909-973-3911</p>
                </v-card-text>
              </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>

<script>
import { getHotel, headersNoAuth } from '../links'

export default {
  title: '-Room reservation',
  data () {
    return {
      loaded: true,
      snackbar: false,
      room: 1,
      color: '',
      text: '',
      noData: false,
      data: false,
      hotel: {},
      hotelRooms: [],
      dialog: false,
      dialog1: false,
      markers: [],
      center: {
        lat: 0,
        lng: 0
      },
      current: 0,
      length: null,
      images: [],
      allrooms: [],
      availableRooms: [],
      selectedRooms: [],
      supplements: []
    }
  },
  methods: {
    openMap (position) {
      this.dialog = !this.dialog
      this.center.lat = parseFloat(position.Latitude)
      this.center.lng = parseFloat(position.Longitude)
      this.markers.push({ position: this.center })
    },
    next () {
      this.current = this.current + 1 === this.length
        ? 0
        : this.current + 1
    },
    prev () {
      this.current = this.current - 1 < 0
        ? this.length - 1
        : this.current - 1
    },
    hideElement (el, n) {
      if (el === 'btn') document.getElementById(`btn-${n}`).style.display = 'none'
      else {
        document.getElementById(`card-${n}`).style.display = 'none'
        this.current = this.current + 1 === this.length ? 0 : this.current + 1
      }
    },
    formatDate (date) {
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    roomImageError (i) {
      document.getElementById(`room-image-${i}`).style.display = 'none'
    },
    getAvailableRooms (index, compination) {
      compination = [...new Set(compination)]
      this.availableRooms = []
      this.selectedRooms = []
      const checkboxes = document.getElementsByClassName('check')
      checkboxes.forEach(checkbox => {
        checkbox.checked = false
      })
      // this.availableRooms.push(this.allrooms[index])
      this.allrooms.forEach(room => {
        if (room.roomIndex === index) this.availableRooms.push(room)
        if (this.$store.state.hotelSearch.guestsInfo.length > 1 || this.$store.state.roomsNo > 1) {
          for (let i = 0; i < compination.length; i++) {
            if (room.roomIndex === compination[i] && room.roomIndex !== index) this.availableRooms.push(room)
          }
        }
      })
    },
    selectRoom (room, i) {
      if (this.selectedRooms.length < this.$store.state.hotelSearch.guestsInfo.length && document.getElementById(`checkbox-${i}`).checked) {
        this.selectedRooms.push(room)
        document.getElementById(`checkbox-${i}`).checked = true
      } else if (this.selectedRooms.length <= this.$store.state.hotelSearch.guestsInfo.length && !document.getElementById(`checkbox-${i}`).checked) {
        this.selectedRooms = this.selectedRooms.filter((val, index, arr) => {
          return val.roomIndex !== room.roomIndex
        })
        document.getElementById(`checkbox-${i}`).checked = false
      } else {
        document.getElementById(`checkbox-${i}`).checked = false
      }
    },
    reserve () {
      const params = {
        sessionId: this.$store.state.hotelSessionId,
        hotelIndex: this.$store.state.hotelIndex,
        hotelCode: this.$store.state.hotelCode,
        rooms: this.selectedRooms,
        hotel: this.hotel,
        supplements: this.supplements
      }
      this.$store.state.hotelParams = params
      this.$store.dispatch('setShowHotelBook', true)
    }
  },
  created () {
    const params = this.$store.state
    this.$http.get(getHotel(params.hotelSessionId, params.hotelIndex, params.hotelCode), { headers: headersNoAuth() }).then(res => {
      if (res.body.status === true) {
        this.loaded = false
        this.data = true
        this.hotel = res.body.data.Hotel
        this.length = res.body.data.Hotel.images.length
        this.images = res.body.data.Hotel.images
        this.allrooms = res.body.data.HotelRooms
        let roomIndex = ''
        if (typeof this.allrooms !== 'string') {
          this.allrooms.forEach(room => {
            if (room.supplements) {
              room.supplements.forEach(sup => {
                if (sup.isMandatory) this.supplements.push({ sup: sup.id, room: room.roomIndex, accept: true })
              })
            }
            if (room.roomIndex !== roomIndex) {
              roomIndex = room.roomIndex
              this.hotelRooms.push(room)
            } else roomIndex = room.roomIndex
          })
          this.getAvailableRooms(this.hotelRooms[0].roomIndex, this.hotelRooms[0].roomCombination ? this.hotelRooms[0].roomCombination : [])
        }
      } else {
        this.loaded = false
        this.snackbar = true
        this.color = 'error'
        this.text = 'Sorry, We couldnot get any response'
        this.noData = true
      }
    }, () => {
      this.loaded = false
      this.snackbar = true
      this.color = 'error'
      this.text = 'Sorry, We couldnot get any response'
      this.noData = true
    })
  }
}
</script>

<style>
  .check {
    width: 19px;
    height: 50px;
    position: absolute;
    top: 0;
    left: 1px;
  }
</style>
