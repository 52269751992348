import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentSearch: '',
    airports: {
      departureAirports: [null, null, null],
      arrivalAirports: [null, null, null]
    },
    flightDates: {
      departureDates: [null, null, null],
      arrivalDate: null
    },
    passengers: {
      adults: 1,
      children: 0,
      infants: 0,
      classType: 'Y'
    },
    isDirectFlights: false,
    preferredAirline: null,
    flightResults: {},
    flightType: '',
    flightsTimeOut: false,
    flightsTimeOutFunction: null,
    flightsSearchTimeOutFunction: null,
    showFlightResults: false,
    showFlightBook: false,
    flightId: null,
    relatedFlightId: null,
    carInfo: {
      pickupLocation: null,
      dropLocation: null,
      pickup: null,
      drop: null,
      pickupTime: null,
      dropTime: null
    },
    carSearchId: '',
    carId: null,
    cars: null,
    carsTimeOut: false,
    carsTimeOutFunction: null,
    carsSearchTimeOutFunction: null,
    showCarResults: false,
    showCarBook: false,
    hotelTimeOut: false,
    hotelTimeOutFunction: null,
    hotelSearchTimeOutFunction: null,
    showHotelResults: false,
    showHotelBook: false,
    showOneHotel: false,
    hotelSearch: {
      checkInDate: null,
      checkOutDate: null,
      destination: null,
      guestsInfo: [
        {
          adults: 1,
          children: 0,
          childrenAges: []
        }
      ]
    },
    hotels: null,
    hotelSessionId: '',
    hotelIndex: '',
    hotelCode: '',
    roomsNo: '',
    hotelParams: null,
    countryCode: 'US',
    writtenAddress: '',
    writtenCity: '',
    writtenState: '',
    writtenCountry: 'US',
    zipCode: ''
  },
  mutations: {
    setAirports (state, payload) {
      switch (payload.type) {
        case 'departure1':
          state.airports.departureAirports[0] = payload.data
          break
        case 'departure2':
          state.airports.departureAirports[1] = payload.data
          break
        case 'departure3':
          state.airports.departureAirports[2] = payload.data
          break
        case 'arrival1':
          state.airports.arrivalAirports[0] = payload.data
          break
        case 'arrival2':
          state.airports.arrivalAirports[1] = payload.data
          break
        case 'arrival3':
          state.airports.arrivalAirports[2] = payload.data
          break
        case 'carPickup':
          state.carInfo.pickupLocation = payload.data
          break
        case 'carDrop':
          state.carInfo.dropLocation = payload.data
          break
        default:
          break
      }
    },
    setDates (state, payload) {
      switch (payload.type) {
        case 'departure1':
          state.flightDates.departureDates[0] = payload.data
          break
        case 'departure2':
          state.flightDates.departureDates[1] = payload.data
          break
        case 'departure3':
          state.flightDates.departureDates[2] = payload.data
          break
        case 'arrival':
          state.flightDates.arrivalDate = payload.data
          break
        case 'pickup':
          state.carInfo.pickup = payload.data
          break
        case 'drop':
          state.carInfo.drop = payload.data
          break
        default:
          break
      }
    },
    setPassengersAdults (state, payload) {
      state.passengers.adults = payload
    },
    setPassengersChildren (state, payload) {
      state.passengers.children = payload
    },
    setPassengersInfants (state, payload) {
      state.passengers.infants = payload
    },
    setPassengersClass (state, payload) {
      state.passengers.classType = payload
    },
    setPreferredAirline (state, payload) {
      state.preferredAirline = payload
    },
    setDirectFlights (state, payload) {
      state.isDirectFlights = payload
    },
    setFlightResults (state, payload) {
      state.flightResults = payload
    },
    setFlightsMinPrice (state, payload) {
      state.flightResults.minPrice = payload
    },
    setFlightsMaxPrice (state, payload) {
      state.flightResults.maxPrice = payload
    },
    updateFlights (state, payload) {
      state.flightResults.flights = payload
    },
    setFlightsTimeOut (state, payload) {
      state.flightsTimeOut = payload
    },
    setFlightsTimeOutFunction (state, payload) {
      state.flightsTimeOutFunction = payload
    },
    setFlightsSearchTimeoutFunction (state, payload) {
      state.flightsSearchTimeOutFunction = payload
    },
    setType (state, payload) {
      state.flightType = payload
    },
    setShowFlightBook (state, payload) {
      state.showFlightBook = payload
    },
    setflightid (state, payload) {
      state.flightId = payload
    },
    setRelatedFlightId (state, payload) {
      state.relatedFlightId = payload
    },
    setShowFlightResults (state, payload) {
      state.showFlightResults = payload
    },
    setAltAirlines (state, payload) {
      if (state.flightResults.airlines.length > 0) {
        payload.forEach(item => {
          state.flightResults.airlines.push(item)
        })
      } else {
        payload.forEach(item => {
          state.flightResults.airlines.push(item)
        })
      }
    },
    setShowCarResults (state, payload) {
      state.showCarResults = payload
    },
    setCarTimes  (state, payload) {
      payload.type === 'Pickup Time' ? state.carInfo.pickupTime = payload.data : state.carInfo.dropTime = payload.data
    },
    setCars (state, payload) {
      state.cars = payload
    },
    setCarsTimeOut (state, payload) {
      state.carsTimeOut = payload
    },
    setCarsTimeOutFunction (state, payload) {
      state.carsTimeOutFunction = payload
    },
    setCarsSearchTimeOutFunction (state, payload) {
      state.carsSearchTimeOutFunction = payload
    },
    setShowCarBook (state, payload) {
      state.showCarBook = payload
    },
    setShowHotelResults (state, payload) {
      state.showHotelResults = payload
    },
    setShowHotelBook (state, payload) {
      state.showHotelBook = payload
    },
    setShowOneHotel (state, payload) {
      state.showOneHotel = payload
    },
    setHotelTimeOut (state, payload) {
      state.hotelTimeOut = payload
    },
    setHotelTimeOutFunction (state, payload) {
      state.hotelTimeOutFunction = payload
    },
    setHotelSearchTimeoutFunction (state, payload) {
      state.hotelSearchTimeOutFunction = payload
    },
    setHotelDestination (state, payload) {
      state.hotelSearch.destination = payload
    },
    setHotelCheckInDate (state, payload) {
      state.hotelSearch.checkInDate = payload
    },
    setHotelCheckOutDate (state, payload) {
      state.hotelSearch.checkOutDate = payload
    },
    setHotelGuestsInfo (state, payload) {
      state.hotelSearch.guestsInfo = payload
    },
    setCountryCode (state, payload) {
      state.countryCode = payload
    },
    setHotels (state, payload) {
      state.hotels = payload
    },
    setWrittenAddress (state, payload) {
      state.writtenAddress = payload
    },
    setWrittenCity (state, payload) {
      state.writtenCity = payload
    },
    setWrittenState (state, payload) {
      state.writtenState = payload
    },
    setWrittenCountry (state, payload) {
      state.writtenCountry = payload
    },
    setZipCode (state, payload) {
      state.zipCode = payload
    },
    removeAirportsData (state) {
      state.airports.departureAirports[0] = null
      state.airports.departureAirports[1] = null
      state.airports.departureAirports[2] = null
      state.airports.arrivalAirports[0] = null
      state.airports.arrivalAirports[1] = null
      state.airports.arrivalAirports[2] = null
    },
    removeDatesData (state) {
      state.flightDates.departureDates[0] = null
      state.flightDates.departureDates[1] = null
      state.flightDates.departureDates[2] = null
      state.flightDates.arrivalDate = null
    },
    removePassengersData (state) {
      state.passengers = {
        adults: 1,
        children: 0,
        infants: 0,
        classType: 'Y'
      }
    },
    removeSegment (state) {
      state.airports.departureAirports[2] = null
      state.airports.arrivalAirports[2] = null
      state.flightDates.departureDates[2] = null
    },
    removetFlightResults (state) {
      state.flightResults = {}
    },
    clearFlightsTimeoutFunction (state) {
      clearTimeout(state.flightsTimeOutFunction)
    },
    clearFlightsSearchTimeoutFunction (state) {
      clearTimeout(state.flightsSearchTimeOutFunction)
    },
    clearDropLocation (state) {
      state.carInfo.dropLocation = null
    },
    clearCarsTimeOutFunction (state) {
      clearTimeout(state.carsTimeOutFunction)
    },
    clearCarsSearchTimeOutFunction (state) {
      clearTimeout(state.carsSearchTimeOutFunction)
    },
    clearHotelGuests (state) {
      state.guestsInfo = [
        {
          adults: 1,
          children: 0,
          childrenAges: []
        }
      ]
    },
    clearHotelTimeoutFunction (state) {
      clearTimeout(state.hotelTimeOutFunction)
    },
    clearHotelSearchTimeoutFunction (state) {
      clearTimeout(state.hotelSearchTimeOutFunction)
    }
  },
  actions: {
    setAirports (context, payload) {
      context.commit('setAirports', payload)
    },
    setDates (context, payload) {
      context.commit('setDates', payload)
    },
    setPassengersAdults (context, payload) {
      context.commit('setPassengersAdults', payload)
    },
    setPassengersChildren (context, payload) {
      context.commit('setPassengersChildren', payload)
    },
    setPassengersInfants (context, payload) {
      context.commit('setPassengersInfants', payload)
    },
    setPassengersClass (context, payload) {
      context.commit('setPassengersClass', payload)
    },
    setPreferredAirline (context, payload) {
      context.commit('setPreferredAirline', payload)
    },
    setFlightResults (context, payload) {
      context.commit('setFlightResults', payload)
    },
    setFlightsMinPrice (context, payload) {
      context.commit('setFlightsMinPrice', payload)
    },
    setFlightsMaxPrice (context, payload) {
      context.commit('setFlightsMaxPrice', payload)
    },
    setDirectFlights (context, payload) {
      context.commit('setDirectFlights', payload)
    },
    updateFlights (context, payload) {
      context.commit('updateFlights', payload)
    },
    setType (context, payload) {
      context.commit('setType', payload)
    },
    setAltAirlines (context, payload) {
      context.commit('setAltAirlines', payload)
    },
    setFlightsTimeOut (context, payload) {
      context.commit('setFlightsTimeOut', payload)
    },
    setFlightsTimeOutFunction (context, payload) {
      context.commit('setFlightsTimeOutFunction', payload)
    },
    setFlightsSearchTimeoutFunction (context, payload) {
      context.commit('setFlightsSearchTimeoutFunction', payload)
    },
    setShowFlightBook (context, payload) {
      context.commit('setShowFlightBook', payload)
    },
    setflightid (context, payload) {
      context.commit('setflightid', payload)
    },
    setRelatedFlightId (context, payload) {
      context.commit('setRelatedFlightId', payload)
    },
    setShowFlightResults (context, payload) {
      context.commit('setShowFlightResults', payload)
    },
    setShowCarResults (context, payload) {
      context.commit('setShowCarResults', payload)
    },
    setCarTimes (context, payload) {
      context.commit('setCarTimes', payload)
    },
    setCars (context, payload) {
      context.commit('setCars', payload)
    },
    setCarsTimeOut (context, payload) {
      context.commit('setCarsTimeOut', payload)
    },
    setCarsTimeOutFunction (context, payload) {
      context.commit('setCarsTimeOutFunction', payload)
    },
    setCarsSearchTimeOutFunction (context, payload) {
      context.commit('setCarsSearchTimeOutFunction', payload)
    },
    setShowCarBook (context, payload) {
      context.commit('setShowCarBook', payload)
    },
    setHotelTimeOut (context, payload) {
      context.commit('setHotelTimeOut', payload)
    },
    setHotelTimeOutFunction (context, payload) {
      context.commit('setHotelTimeOutFunction', payload)
    },
    setHotelSearchTimeoutFunction (context, payload) {
      context.commit('setHotelSearchTimeoutFunction', payload)
    },
    setHotelDestination (context, payload) {
      context.commit('setHotelDestination', payload)
    },
    setHotelCheckInDate (context, payload) {
      context.commit('setHotelCheckInDate', payload)
    },
    setHotelCheckOutDate (context, payload) {
      context.commit('setHotelCheckOutDate', payload)
    },
    setHotelGuestsInfo (context, payload) {
      context.commit('setHotelGuestsInfo', payload)
    },
    setCountryCode (context, payload) {
      context.commit('setCountryCode', payload)
    },
    setHotels (context, payload) {
      context.commit('setHotels', payload)
    },
    setShowHotelResults (context, payload) {
      context.commit('setShowHotelResults', payload)
    },
    setShowHotelBook (context, payload) {
      context.commit('setShowHotelBook', payload)
    },
    setShowOneHotel (context, payload) {
      context.commit('setShowOneHotel', payload)
    },
    setWrittenAddress (context, payload) {
      context.commit('setWrittenAddress', payload)
    },
    setWrittenCity (context, payload) {
      context.commit('setWrittenCity', payload)
    },
    setWrittenState (context, payload) {
      context.commit('setWrittenState', payload)
    },
    setWrittenCountry (context, payload) {
      context.commit('setWrittenCountry', payload)
    },
    setZipCode (context, payload) {
      context.commit('setZipCode', payload)
    },
    removeAirportsData (context) {
      context.commit('removeAirportsData')
    },
    removeDatesData (context) {
      context.commit('removeDatesData')
    },
    removeSegment (context) {
      context.commit('removeSegment')
    },
    removePassengersData (context) {
      context.commit('removePassengersData')
    },
    removetFlightResults (context) {
      context.commit('removetFlightResults')
    },
    clearFlightsTimeoutFunction (context) {
      context.commit('clearFlightsTimeoutFunction')
    },
    clearFlightsSearchTimeoutFunction (context) {
      context.commit('clearFlightsSearchTimeoutFunction')
    },
    clearDropLocation (context) {
      context.commit('clearDropLocation')
    },
    clearCarsTimeOutFunction (context) {
      context.commit('clearCarsTimeOutFunction')
    },
    clearCarsSearchTimeOutFunction (context) {
      context.commit('clearCarsSearchTimeOutFunction')
    },
    clearHotelGuests (context) {
      context.commit('clearHotelGuests')
    },
    clearHotelTimeoutFunction (context) {
      context.commit('clearHotelTimeoutFunction')
    },
    clearHotelSearchTimeoutFunction (context) {
      context.commit('clearHotelSearchTimeoutFunction')
    }
  },
  plugins: [createPersistedState()]
})
