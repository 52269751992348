<template>
    <div>
        <table v-if="type === 'flights'">
            <tr>
                <th>Title</th>
                <th>Name</th>
                <th>Birth Date</th>
                <th>Passport Number</th>
                <th>Expire Date</th>
                <th>Country</th>
            </tr>
            <tr v-for="(item, i) in items" :key="i">
                <td>{{item.passengerTitle}}</td>
                <td>{{item.passengerFirstName}} {{item.passengerLastName}}</td>
                <td>{{formatDate(item.date_of_birth)}}</td>
                <td>{{item.passport_number}}</td>
                <td>{{formatDate(item.passport_expire_date)}}</td>
                <td>{{item.passport_issue_country}}</td>
            </tr>
        </table>
        <table v-if="type === 'hotels'" border="1">
            <tr>
                <th>Room</th>
                <th>Title</th>
                <th>Name</th>
                <th>Age</th>
                <th>Leader</th>
            </tr>
            <tr v-for="(item, i) in items" :key="i">
                <td>{{item.InRoom}}</td>
                <td>{{item.title}}</td>
                <td>{{item.firstName}} {{item.lastName}}</td>
                <td>{{item.guestType}}</td>
                <td>{{item.isLead === 1 ? 'True' : 'False'}}</td>
            </tr>
        </table>
        <table v-if="type === 'cars'"></table>
    </div>
</template>

<script>
export default {
  props: ['type', 'items'],
  data () {
    return {
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      return `${day} ${months[month - 1]} ${year}`
    }
  }
}
</script>

<style scoped>
    table {
        border: 1.5px solid #d7d7d7;
        text-align: center;
        width: 100%;
    }
</style>
