<template>
    <!-- <phone
    size="lg"
    :default-country-code="$store.state.countryCode"
    :preferred-countries="[$store.state.countryCode, 'EG', 'US']"
    required
    :countries-height="25"
    no-flags
    @update="assignPhone"
    v-model="initialPhone"
    >
      <template v-slot:arrow>
        <v-icon>mdi-chevron-down</v-icon>
      </template>
    </phone> -->
    <vue-tel-input
    :preferredCountries="['US']"
    defaultCountry="US"
    invalidMsg="Phone number is invalid!"
    :inputOptions="{required: true, placeholder: 'Enter phone number'}"
    validCharactersOnly
    v-model="initialPhone"
    @input="assignPhone"
    id="phone-input"
    >
      <template v-slot:arrow-icon>
        <v-icon small>mdi-chevron-down</v-icon>
      </template>
    </vue-tel-input>
</template>

<script>
// import phone from 'vue-phone-number-input'
// import 'vue-phone-number-input/dist/vue-phone-number-input.css'
const VueTelInput = () =>
  Promise.all([
    import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input'),
    import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input/dist/vue-tel-input.css')
  ]).then(([{ VueTelInput }]) => VueTelInput)
// import { VueTelInput } from 'vue-tel-input'
// import 'vue-tel-input/dist/vue-tel-input.css'

export default {
  props: ['phone'],
  components: {
    // phone
    VueTelInput
  },
  data () {
    return {
      initialPhone: ''
    }
  },
  methods: {
    assignPhone (phone, phoneObj) {
      this.$emit('update', phoneObj)
    }
  },
  mounted () {
    if (this.phone) this.initialPhone = this.phone
  }
}
</script>

<style>
.vue-tel-input {
  height: 56px;
}
</style>
