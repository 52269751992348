<template>
  <div>
    <v-progress-linear
    indeterminate
    color="secondary"
    height="10"
    top
    :active="loaded"
    ></v-progress-linear>
    <v-container>
      <div>
        <v-overlay :value="showOverlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-snackbar v-model="snackbar" :color="color" top :timeout="30000">
          <v-row>
          {{ text }}
          <v-spacer></v-spacer>
          <v-icon color="white" @click="snackbar = false">mdi-close</v-icon>
          </v-row>
        </v-snackbar>
        <div v-if="!loaded && !dataReady">
            <p class="font-weight-bold body-1 text-center">The room or rooms you want aren't available any more</p>
        </div>
        <div v-if="!loaded && dataReady">
          <div v-if="availability.AvailableForBook || availability.AvailableForConfirmBook">
            <vue-good-wizard
            :steps="steps"
            :onNext="nextClicked"
            :onBack="backClicked"
            color="secondary"
            finalStepLabel="Finish"
            :nextStepLabel="nextLabel"
            ref="myWizard"
            >
              <div slot="page1" color="secondary">
                <v-alert
                border="left"
                color="warning"
                dark
                v-model="alert"
                dismissible
                >
                  {{alertText}}
                </v-alert>
                <v-row class="ma-2">
                  <h3 class="font-weight-bold">{{hotel.HotelName}}</h3>
                  <v-rating :length="hotel.HotelStars" color="warning" readonly small :value="Number(hotel.HotelStars)" full-icon="mdi-star"></v-rating>
                  <v-spacer></v-spacer>
                  <p class="body-1 font-weight-bold primary--text">${{changeRoomsPrice()}}</p>
                </v-row>
                <v-row class="mx-auto">
                  <v-icon small class="mt-0" color="secondary">mdi-map-marker</v-icon>
                  <p class="body-2 mb-0 mt-1">{{hotel.HotelAddress}},{{hotel.HotelCity}},{{hotel.HotelCountry}}</p>
                  <v-btn text color="blue" small @click="openMap(hotel.map)">
                    Show on map
                  </v-btn>
                </v-row>
                <v-row class="mx-auto">
                  <v-icon small class="mr-1" color="secondary">mdi-phone</v-icon>{{hotel.HotelPhone}}
                  <v-icon small class="mr-1 ml-1" color="secondary">mdi-fax</v-icon>{{hotel.HotelFax}}
                </v-row>
                <div>
                  <p class="body-1 font-weight-bold mt-5">Hotel policy</p>
                  <div v-for="(policy, n) in availability.HotelPolicies" :key="n">
                    <div v-text="policy"></div>
                  </div>
                </div>
                <div>
                  <p class="body-1 font-weight-bold mt-5">Cancelation</p>
                  <p class="body-2">{{availability.DefaultPolicy}}</p>
                  <p v-if="availability.lastCancellationDeadLine">Cancellation DeadLine: {{availability.lastCancellationDeadLine}}</p>
                  <div v-if="availability.cancelPolicies && availability.cancelPolicies.length > 0">
                      <p v-for="(policy, i) in availability.cancelPolicies" :key="i">{{policy.roomType}} From {{policy.fromDate}} to {{policy.toDate}} cancelation charge {{policy.chargeType === 'Fixed' ? '$' : '%' }}{{policy.cancellationCharge}}</p>
                  </div>
                </div>
                <v-checkbox v-model="acceptCancellation" label="I Accept cancellation policies"></v-checkbox>
              </div>
              <div slot="page2">
                <v-row>
                  <v-form v-model="valid" ref="form">
                    <v-container>
                      <p class="body-1 font-weight-bold secondary--text">Contact person details</p>
                      <v-row>
                        <v-col cols="12" md="6" class="pt-0">
                          <!-- <v-text-field
                              outlined
                              v-model="phone"
                              :rules="[v => !!v || 'Phone is required', v => (!isNaN(v)) || 'Phone must be only numbers']"
                              class="remove-controls"
                              label="Phone"
                              type="number"
                              required
                              autocomplete="false"
                              color="blue"
                            ></v-text-field> -->
                            <phoneInput :phone="phone.nationalNumber" @update="assignPhone" />
                        </v-col>
                        <v-col cols="12" md="6" class="pt-0">
                            <v-text-field
                                outlined
                                v-model="email"
                                :rules="[v => !!v || 'E-mail is required', v => /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(v) || 'E-mail must be valid']"
                                label="E-mail"
                                required
                                color="blue"
                            ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row v-for="(room, i) in rooms" :key="i">
                        <v-col cols="12">
                          <p class="body-1 font-weight-bold secondary--text">Room {{i + 1}}</p>
                        </v-col>
                        <v-divider></v-divider>
                        <v-row v-for="n in $store.state.hotelSearch.guestsInfo[i].adults" :key="(n * 100) + previousNumber('adult', i)">
                          <v-col cols="12">Adult {{n}}</v-col>
                          <v-col cols="12" sm="2">
                            <v-select
                              v-model="guestTitles[(n - 1) + (i) + (previousNumber('adult', i))]"
                              :items="['Mr', 'Mrs', 'Ms', 'Miss']"
                              label="Title"
                              outlined
                              required
                              :rules="[v => !!v || 'Item is required']"
                              color="blue"
                              >
                                <template v-slot:selection="{ item }">
                                  <span class="primary--text caption">{{item}}</span>
                                </template>
                              </v-select>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-text-field
                              outlined
                              v-model="adultFirstNames[(n - 1) + (i) + (previousNumber('adult', i))]"
                              :rules="nameRule"
                              label="First name"
                              required
                              color="blue"
                              autocomplete="off"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-text-field
                              outlined
                              v-model="adultLastNames[(n - 1) + (i) + (previousNumber('adult', i))]"
                              :rules="nameRule"
                              label="Last name"
                              required
                              color="blue"
                              autocomplete="off"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="2">
                              <v-row justify="space-around">
                              <p class="ma-0 mt-3">Leader</p>
                              <input type="radio"
                              style="width: 19px; height: 50px;"
                              v-model="isLeader"
                              :value="(n - 1) + (i) + (previousNumber('adult', i))"
                              >
                              </v-row>
                            </v-col>
                        </v-row>
                        <v-row v-for="n in $store.state.hotelSearch.guestsInfo[i].children" :key="(n) + previousNumber('child', i)">
                          <v-col cols="12">Child {{n}}</v-col>
                          <v-col cols="2">
                            <v-select
                              v-model="childrenTitles[(n - 1) + (i) + (previousNumber('child', i))]"
                              :items="['Mr', 'Miss']"
                              label="Title"
                              outlined
                              required
                              :rules="[v => !!v || 'Item is required']"
                              color="blue"
                              >
                                <template v-slot:selection="{ item }">
                                  <span class="primary--text caption">{{item}}</span>
                                </template>
                              </v-select>
                            </v-col>
                          <v-col cols="4">
                              <v-text-field
                              outlined
                              v-model="childrenFirstNames[(n - 1) + (i) + (previousNumber('child', i))]"
                              :rules="nameRule"
                              label="First name"
                              required
                              color="blue"
                              autocomplete="off"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                              <v-text-field
                              outlined
                              v-model="childrenLastNames[(n - 1) + (i) + (previousNumber('child', i))]"
                              :rules="nameRule"
                              label="Last name"
                              required
                              color="blue"
                              autocomplete="off"
                              ></v-text-field>
                            </v-col>
                        </v-row>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-row>
              </div>
              <div slot="page3">
                <bookinData type="hotels" :items="guestsData" />
                <address-info />
                <v-form v-model="valid1" ref="form1">
                  <v-container fluid>
                    <v-divider></v-divider>
                    <credit @card="setCardData" @expire="setCardExpiry" @cvv="setCVV" />
                  </v-container>
                </v-form>
                <v-divider></v-divider>
                <v-row>
                    <v-col cols="12">
                      <v-row justify="space-between">
                        <v-col cols="6">
                          <p class="body-2 font-weight-regular grey--text text-wrap">
                              By clicking Confirm & Book button you agree with terms and conditionals and money back gurantee. Thank you for trusting our service.
                          </p>
                        </v-col>
                        <v-col cols="6">
                          <p v-if="paymentType !== 'points'" class="display-1 font-weight-bold primary--text text-right">${{changeRoomsPrice()}}</p>
                          <p v-else class="display-1 font-weight-bold primary--text text-right">$0</p>
                          <!-- - {{(changeRoomsPrice() * 170).toFixed(2)}} P-->
                        </v-col>
                      </v-row>
                    </v-col>
                </v-row>
              </div>
              <div slot="page4">
                <h3 class="display-1 font-weight-thin primary--text">Your rooms were booked successfully, we will send you an email with more details.</h3>
                <h3 class="body-1 font-weight-bold primary--text mt-5">Your booking number is {{bookingNo}}</h3>
              </div>
            </vue-good-wizard>
          </div>
          <div v-else>
            <p class="font-weight-bold body-1 text-center">The room or rooms you want aren't available any more</p>
          </div>
        </div>
      </div>
      <v-dialog
      v-model="dialog"
      max-width="80%"
      >
          <gmap-map
          :center="center"
          :zoom="16"
          style="width:100%;  height: 500px;"
          zoomControl
          class="mt-1"
          >
              <gmap-marker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              @click="center=m.position"
              :clickable="true"
              :draggable="true"
              ></gmap-marker>
          </gmap-map>
      </v-dialog>
      <v-dialog
      v-model="priceChangeDialog"
      max-width="500"
      persistent
      >
        <v-card>
          <v-card-title
            class="headline secondary"
            primary-title
          >
            Price Change
          </v-card-title>

          <v-card-text>
            Please note that some rooms' prices have been changeda and the new price is <span class="body-1 primary--text font-weight-bold">${{getNewPrice()}}</span>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="priceChangeDialog = false; $store.dispatch('setShowOneHotel', false); this.$store.dispatch('setShowHotelBook', false); this.$store.dispatch('setShowHotelResults', true)"
            >
              Back To Results
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="priceChangeDialog = false; showOverlay = true; book()"
            >
              Book
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { bookHotel, hotelAvailability, headersNoAuth } from '../links'
import bookinData from '../components/bookingDataTable'
import phoneInput from '../components/mobile.vue'
import credit from '../components/credit'
import addressInfo from '../components/address info'

export default {
  title: '-Hotel Book',
  components: {
    bookinData,
    phoneInput,
    credit,
    addressInfo
  },
  data () {
    return {
      showAlert: true,
      loaded: true,
      dataReady: false,
      dialog: false,
      alert: false,
      paymentType: 'credit',
      acceptCancellation: false,
      alertText: '',
      currentPage: 0,
      searcTerms: {},
      hotel: {},
      rooms: [],
      steps: [
        {
          label: 'Review',
          slot: 'page1'
        },
        {
          label: 'Guests',
          slot: 'page2'
        },
        {
          label: 'Payment & Booking',
          slot: 'page3'
        },
        {
          label: 'Done',
          slot: 'page4'
        }
      ],
      nameRule: [v => (!!v && v.length > 1) || 'Item is required at least 2 characters',
        v => /^[A-Za-z][A-Za-z]*$/.test(v) || 'Name Must be letters only with no spaces'
      ],
      nextLabel: 'Continue',
      valid: true,
      valid1: true,
      snackbar: false,
      text: '',
      color: '',
      showOverlay: false,
      isLeader: 0,
      guestTitles: [],
      childrenTitles: [],
      adultFirstNames: [],
      adultLastNames: [],
      childrenFirstNames: [],
      childrenLastNames: [],
      email: '',
      phone: '',
      cardNum: '',
      cardRules: [
        v => !!v || 'Card number is required',
        v => (!isNaN(v)) || 'Card number must be only numbers'
      ],
      cardCode: '',
      cardExpireDate: '',
      markers: [],
      center: {
        lat: 0,
        lng: 0
      },
      availability: {},
      menu: false,
      supplements: [],
      bookingNo: '',
      guestsData: [],
      priceChangeDialog: false,
      newPriceBody: {}
    }
  },
  computed: {
    cardExpireDateText () {
      if (this.cardExpireDate) return this.formatDate(this.cardExpireDate)
      else return ''
    }
  },
  methods: {
    sendPaymentDataToAnet () {
      const authData = {}
      authData.clientKey = process.env.NODE_ENV === 'development' ? '5RwWyDRc9cD6Cm5K3dsK33zWuWz6qRz6V2bC3nz4q3RyTfLVWt5A4XS9RcK2w8AY' : '7e78jU6TUPp75pFd48aj85zE9cS6T98PYLS2w3eMmnFqvN67VKLMu45jL788FAk9'
      authData.apiLoginID = process.env.NODE_ENV === 'development' ? '4U9Ye5Hdq' : '979GuM8CrN'
      const cardData = {}
      cardData.cardNumber = this.cardNum
      cardData.month = `${this.cardExpireDate.split('-')[1]}`
      cardData.year = `${this.cardExpireDate.split('-')[0]}`
      cardData.cardCode = this.cardCode
      cardData.cardHolder = this.cardHolder

      const secureData = {}
      secureData.authData = authData
      secureData.cardData = cardData

      window.Anet.dispatchData(secureData, (response) => {
        this.showOverlay = false
        if (response.messages.resultCode === 'Error') {
          for (let index = 0; index < response.messages.message.length; index++) {
            this.snackbar = true
            this.text = 'error :' + response.messages.message[index].code + ': ' + response.messages.message[index].text
            this.color = 'red'
          }
        } else {
          this.authorizeNetToken = response.opaqueData
          this.book()
        }
      })
    },
    assignPhone (phone) {
      this.phone = phone
    },
    setCardData (number, type) {
      this.cardNum = number
      switch (type) {
        case 'visa':
          this.creditType = 'VI'
          break
        case 'mastercard':
          this.creditType = 'CA'
          break
        case 'american-express':
          this.creditType = 'AX'
          break
        case 'discover':
          this.creditType = 'DS'
          break
        case 'diners-club':
          this.creditType = 'DN'
          break
        default:
          this.creditType = type
          break
      }
    },
    setCardExpiry (date) {
      this.cardExpireDate = date
    },
    setCVV (cvv) {
      this.cardCode = cvv
    },
    formatDate (date) {
      if (!date) return null
      const [year, month] = date.split('-')
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const newDate = `${months[month - 1]} ${year}`
      return newDate
    },
    openMap (position) {
      this.dialog = !this.dialog
      this.center.lat = parseFloat(position.Latitude)
      this.center.lng = parseFloat(position.Longitude)
      this.markers.push({ position: this.center })
    },
    changeRoomsPrice () {
      let price = 0
      if (this.availability.priceChanged === 'true') {
        for (let i = 0; i < this.rooms.length; i++) {
          this.rooms[i].rateSummary = this.availability.rooms[i].rateSummary
        }
        let text = 'Please notice that the rooms prices has changed as follows'
        this.rooms.forEach(room => {
          price = price + room.rateSummary.totalFare
        })
        for (let i = 0; i < this.rooms.length; i++) {
          text += ` room number ${i + 1} price is $${this.rooms[i].rateSummary.totalFare} `
        }
        this.alert = true
        this.alertText = text
      } else {
        this.rooms.forEach(room => {
          price = price + room.rateSummary.totalFare
        })
      }
      return price.toFixed(2)
    },
    getNewPrice () {
      let price = 0
      this.rooms.forEach(room => {
        price = price + room.rateSummary.totalFare
      })
      return price.toFixed(2)
    },
    previousNumber (type, i) {
      if (type === 'adult') {
        if (i > 0) {
          let number = 0
          for (let n = 0; n < i; n++) {
            number = number + this.$store.state.hotelSearch.guestsInfo[n].adults
          }
          return number
        } else return 0
      } else {
        if (i > 0) {
          let number = 0
          for (let n = 0; n < i; n++) {
            number = number + this.$store.state.hotelSearch.guestsInfo[n].children
          }
          return number
        } else return 0
      }
    },
    nextClicked (currentPage) {
      try {
        if (currentPage === 0) {
          this.snackbar = false
          if (this.acceptCancellation) {
            return true
          } else {
            this.snackbar = true
            this.color = 'warning'
            this.text = 'Please reead and accept on the cancllation policy to continue'
            return false
          }
        } else if (currentPage === 1) {
          this.currentPage = 1
          this.$refs.form.validate()
          if (this.valid) {
            const fullNames = []
            for (let index = 0; index < this.adultFirstNames.length; index++) {
              fullNames.push(`${this.adultFirstNames[index]} ${this.adultLastNames[index]}`)
            }
            for (let index = 0; index < this.childrenFirstNames.length; index++) {
              fullNames.push(`${this.childrenFirstNames[index]} ${this.childrenLastNames[index]}`)
            }
            if (new Set(fullNames).size === fullNames.length) {
              if (this.phone.valid) {
                this.snackbar = false
                this.prepareGuestsData()
                return true
              } else {
                this.snackbar = true
                this.color = 'error'
                this.text = 'Please provide a valid phone number'
                return false
              }
            } else {
              this.snackbar = true
              this.color = 'warning'
              this.text = 'Please avoid using similar names for guests'
              return false
            }
          } else {
            this.snackbar = true
            this.color = 'warning'
            this.text = 'Please fill all fields to cotinue'
            return false
          }
        } else if (currentPage === 2) {
          this.currentPage = 2
          this.$refs.form1.validate()
          this.nextLabel = 'Book'
          if (this.valid1) {
            this.snackbar = false
            if ((this.$store.state.writtenAddress && this.paymentType === 'credit') || this.paymentType !== 'credit') {
              if ((this.$store.state.zipCode && this.paymentType === 'credit') || this.paymentType !== 'credit') {
                this.sendPaymentDataToAnet()
              } else {
                this.snackbar = true
                this.color = 'error'
                this.text = 'Please Enter your ZIP code'
              }
            } else {
              this.snackbar = true
              this.color = 'error'
              this.text = 'Please Enter your address'
              document.getElementsByClassName('address-autocomplete')[0].style.borderColor = 'red'
            }
          } else {
            this.snackbar = true
            this.color = 'warning'
            this.text = 'Please fill all fields to Book'
            return false
          }
        } else if (currentPage === 3) {
          this.showAlert = false
          this.$store.dispatch('setShowHotelResults', false)
          this.$store.dispatch('setShowOneHotel', false)
          this.$store.dispatch('setShowHotelBook', false)
          return true
        } else {
          this.nextLabel = 'Continue'
          return true
        }
      } catch (error) {}
    },
    backClicked (currentPage) {
      this.currentPage = currentPage
      if (currentPage === 3) {
        return false
      } else if (currentPage === 2) {
        this.nextLabel = 'Continue'
      } else return true
    },
    prepareGuestsData () {
      this.guestsData = []
      for (let i = 0; i < this.$store.state.hotelSearch.guestsInfo.length; i++) {
        for (let n = 0; n < this.$store.state.hotelSearch.guestsInfo[i].adults; n++) {
          const guest = {
            isLead: this.isLeader === ((this.previousNumber('adult', i)) + i + n) ? 1 : 0,
            title: this.guestTitles[(this.previousNumber('adult', i)) + i + n],
            firstName: this.adultFirstNames[(this.previousNumber('adult', i)) + i + n],
            lastName: this.adultLastNames[(this.previousNumber('adult', i)) + i + n],
            guestType: 'Adult',
            InRoom: i + 1
          }
          this.guestsData.push(guest)
        }
        for (let n = 0; n < this.$store.state.hotelSearch.guestsInfo[i].children; n++) {
          const guest = {
            isLead: 0,
            title: this.childrenTitles[(this.previousNumber('child', i)) + i + n],
            firstName: this.childrenFirstNames[(this.previousNumber('child', i)) + i + n],
            lastName: this.childrenLastNames[(this.previousNumber('child', i)) + i + n],
            guestType: 'Child',
            InRoom: i + 1,
            childAge: this.$store.state.hotelSearch.guestsInfo[i].childrenAges[n]
          }
          this.guestsData.push(guest)
        }
      }
    },
    book () {
      const body = {
        guestNationality: this.$store.state.countryCode,
        sessionId: this.searcTerms.sessionId,
        numberOfRooms: this.rooms.length,
        hotelIndex: this.searcTerms.hotelIndex,
        hotelCode: this.searcTerms.hotelCode,
        hotelName: this.hotel.HotelName,
        creditCardInfo: {
          creditCardHolderName: this.cardHolder,
          descriptor: this.authorizeNetToken.dataDescriptor,
          token: this.authorizeNetToken.dataValue,
          zipCode: this.$store.state.zipCode,
          address: this.$store.state.writtenAddress,
          city: this.$store.state.writtenCity,
          state: this.$store.state.writtenState,
          country: this.$store.state.writtenCountry
        },
        lastCancellationDeadLine: this.availability.lastCancellationDeadLine,
        phoneNumber: this.phone.nationalNumber,
        countryIsoCode: this.phone.countryCode,
        email: this.email,
        guests: this.guestsData,
        rooms: []
      }
      // add room supplements to rooms & add rooms to body
      for (let i = 0; i < this.rooms.length; i++) {
        const supplements = []
        if (this.rooms[i].supplements) {
          this.rooms[i].supplements.forEach(sup => {
            if (sup.isMandatory) {
              supplements.push({
                id: sup.id,
                chargeType: sup.chargeType,
                price: sup.price,
                isSelected: 1
              })
            } else {
              this.supplements.forEach(selectedSup => {
                if (sup.id === selectedSup.id && this.rooms[i].roomIndex === selectedSup.room) {
                  selectedSup.accept ? supplements.push({
                    id: sup.id,
                    chargeType: sup.chargeType,
                    price: sup.price,
                    isSelected: 1
                  }) : supplements.push({
                    id: sup.id,
                    chargeType: sup.chargeType,
                    price: sup.price,
                    isSelected: 0
                  })
                }
              })
            }
          })
        }
        const room = {
          roomType: this.rooms[i].roomName,
          roomIndex: this.rooms[i].roomIndex,
          roomCode: this.rooms[i].roomCode,
          ratePlanCode: this.rooms[i].ratePlanCode,
          roomFare: this.rooms[i].rateSummary.baseFare,
          currency: this.rooms[i].rateSummary.currency,
          roomTax: this.rooms[i].rateSummary.tax,
          totalFare: this.rooms[i].rateSummary.totalFare,
          supplements: supplements
        }
        body.rooms.push(room)
      }
      this.$http.post(bookHotel, body, { headers: headersNoAuth() }).then(response => {
        if (response.body.status === false) {
          this.showOverlay = false
          this.text = response.body.message
          this.color = 'error'
          this.snackbar = true
          this.disableBookingBtn()
        } else if (response.body.status === 406) {
          this.showOverlay = false
          this.priceChangeDialog = true
          this.rooms = response.body.data.NotBooked.rooms
        } else {
          this.showOverlay = false
          this.bookingNo = response.body.data.BookingNo
          this.$store.dispatch('setHotelTimeOut', false)
          if (this.$store.state.hotelTimeOutFunction) this.$store.dispatch('clearHotelTimeoutFunction')
          if (this.$store.state.hotelSearchTimeOutFunction) this.$store.dispatch('clearHotelSearchTimeoutFunction')
          document.querySelector('.wizard__body__actions a[data-v-c21d83ca]').style.display = 'none'
          this.nextLabel = 'Finish'
          this.currentPage = 3
          this.$refs.myWizard.goTo(3)
        }
      }, () => {
        this.showOverlay = false
        this.text = 'Something went wrong, please try again in a few minutes'
        this.color = 'error'
        this.snackbar = true
      }).catch((e) => {
      })
    },
    disableBookingBtn () {
      const btns = document.getElementsByClassName('wizard__next pull-right')
      btns.forEach(btn => {
        btn.style.display = 'none'
      })
      setTimeout(() => {
        btns.forEach(btn => {
          btn.style.display = 'block'
        })
      }, 30000)
    }
  },
  created () {
    this.searcTerms = this.$store.state.hotelParams
    this.hotel = this.searcTerms.hotel
    this.rooms = this.searcTerms.rooms
    this.supplements = this.searcTerms.supplements
    let term = `sessionId=${this.searcTerms.sessionId}&hotelIndex=${this.searcTerms.hotelIndex}&hotelCode=${this.searcTerms.hotelCode}`
    if (this.searcTerms.rooms) {
      const rooms = this.searcTerms.rooms.sort((a, b) => { return a.roomIndex - b.roomIndex })
      rooms.forEach(room => {
        term = term + `&roomIndex[]=${room.roomIndex}`
      })
      this.$http.get(hotelAvailability(term), { headers: headersNoAuth() }).then(res => {
        if (res.body.status === false) {
          this.loaded = false
          this.dataReady = false
        } else {
          this.loaded = false
          this.dataReady = true
          this.availability = res.body.data
        }
      }, () => {
        this.loaded = false
        this.dataReady = false
      })
    } else {
      this.loaded = false
      this.dataReady = false
    }
  }
}
</script>

<style>
   #returnBtn:hover {
    cursor: pointer;
   }
  .ats .wizard__body__actions a[data-v-c21d83ca] {
    background-color:#150958 !important;
  }
  .fare .wizard__body__actions a[data-v-c21d83ca] {
    background-color:#DF731C !important;
  }
  .wizard__body__actions[data-v-c21d83ca] {
    background-color:white !important;
    border-top: 0.1px solid #e3e3e3 !important;
  }
  .wizard__body[data-v-c21d83ca] {
    margin-top: 30px !important;
    min-height: auto !important;
    margin-left: 20px !important;
    margin-right: 10px !important;
    border: none !important;
    box-shadow: 1px 3px 3px gray !important;
  }
  .ats .wizard__step.active .wizard__step__indicator[data-v-c21d83ca] {
    background-color:#150958 !important;
  }
  .ats .wizard__step.active:not(:first-child) .wizard__step__line[data-v-c21d83ca] {
    background-color:#150958 !important;
  }
  .fare .wizard__step.active .wizard__step__indicator[data-v-c21d83ca] {
    background-color:#DF731C !important;
  }
  .fare .wizard__step.active:not(:first-child) .wizard__step__line[data-v-c21d83ca] {
    background-color:#DF731C !important;
  }
  .wizard__arrow[data-v-c21d83ca] {
    border: none !important;
  }
  .remove-controls input[type='number'] {
    -moz-appearance:textfield;
  }
  .remove-controls input::-webkit-outer-spin-button,
  .remove-controls input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
</style>
