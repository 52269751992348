<template>
  <v-col cols="12" :sm="type === 'hotel' ? 12 : 6" :md="type === 'carPickup' || type === 'carDrop' ? 4 : (type === 'airline' ? 3 : '')" :lg="type === 'carPickup' ? 2 : (type === 'airline' ? 3 : '')" class="py-0 my-1 ml-0">
    <div>
      <div :class="type === 'airline' ? 'airports-input airlines' : 'airports-input'">
        <v-icon :color="iconColor">{{icon}}</v-icon>
        <input v-model="select" :id="`autocomplete-${type}`" @keyup="searchAirports(`autocomplete-${type}`)" :placeholder="placeholder" @focus="coloringIcon()" type="text" autocomplete="off">
      </div>
      <div class="airports-list" v-if="items.length > 0">
        <ul>
          <li @click="selectAirport(item)" v-for="(item, i) in items" :key="i">
            <div v-if="type === 'hotel'">
              <v-icon>{{item.isHotel ? 'mdi-bed' : 'mdi-map-marker'}}</v-icon>
              <span class="body-1">{{item.name}}</span>
              <!-- <v-subheader>{{item.countryName}},{{item.countryCode}}</v-subheader> -->
            </div>
            <div v-else-if="type === 'airline'">
              <span class="body-1">{{item.name}}</span>
              <v-subheader>{{item.code}}</v-subheader>
            </div>
            <div v-else>
              <span class="body-1">{{item.Name}}</span>
              <v-subheader>{{item.Country}},{{item.Code}}</v-subheader>
            </div>
            <hr>
          </li>
        </ul>
      </div>
    </div>
  </v-col>
</template>

<script>
import { getAirports, getAirlines, getCities, headersNoAuth } from '../links'

export default {
  props: ['type', 'getSecondDeparture', 'getThirdDeparture'],
  data () {
    return {
      loading: false,
      items: [],
      search: null,
      select: null,
      value: null,
      iconColor: '#757575',
      firstRequest: true
    }
  },
  computed: {
    icon () {
      if (this.type === 'Departure' || this.type === 'Second Departure' || this.type === 'Third Departure') {
        return 'mdi-airplane-takeoff'
      } else if (this.type === 'carDrop' || this.type === 'carDropLarge' || this.type === 'carPickup') {
        return 'mdi-car'
      } else if (this.type === 'hotel') {
        return 'mdi-domain'
      } else if (this.type === 'airline') {
        return 'mdi-airport'
      } else {
        return 'mdi-airplane-landing'
      }
    },
    initialValue () {
      let value
      const airports = this.$store.state.airports
      const carInfo = this.$store.state.carInfo
      // const preferredAirline = this.$store.state.preferredAirline
      switch (this.type) {
        case 'Departure':
          airports.departureAirports[0] !== null ? value = `${airports.departureAirports[0].City},${airports.departureAirports[0].Code}` : value = ''
          break
        case 'Arrival':
          airports.arrivalAirports[0] !== null ? value = `${airports.arrivalAirports[0].City},${airports.arrivalAirports[0].Code}` : value = ''
          break
        case 'Second Departure':
          airports.departureAirports[1] !== null ? value = `${airports.departureAirports[1].City},${airports.departureAirports[1].Code}` : (airports.arrivalAirports[0] !== null ? value = `${airports.arrivalAirports[0].City},${airports.arrivalAirports[0].Code}` : value = '')
          break
        case 'Second Arrival':
          airports.arrivalAirports[1] !== null ? value = `${airports.arrivalAirports[1].City},${airports.arrivalAirports[1].Code}` : value = ''
          break
        case 'Third Departure':
          airports.departureAirports[2] !== null ? value = `${airports.departureAirports[2].City},${airports.departureAirports[2].Code}` : (airports.arrivalAirports[1] !== null ? value = `${airports.arrivalAirports[1].City},${airports.arrivalAirports[1].Code}` : value = '')
          break
        case 'Third Arrival':
          airports.arrivalAirports[2] !== null ? value = `${airports.arrivalAirports[2].City},${airports.arrivalAirports[2].Code}` : value = ''
          break
        case 'carDrop':
          carInfo.dropLocation !== null ? value = `${carInfo.dropLocation.City},${carInfo.dropLocation.Code}` : value = ''
          break
        case 'carDropLarge':
          carInfo.dropLocation !== null ? value = `${carInfo.dropLocation.City},${carInfo.dropLocation.Code}` : value = ''
          break
        case 'carPickup':
          carInfo.pickupLocation !== null ? value = `${carInfo.pickupLocation.City},${carInfo.pickupLocation.Code}` : value = ''
          break
        default:
          break
      }
      return value
    },
    placeholder () {
      let value
      switch (this.type) {
        case 'Departure':
          value = 'From'
          break
        case 'Arrival':
          value = 'To'
          break
        case 'Second Departure':
          value = 'From'
          break
        case 'Second Arrival':
          value = 'To'
          break
        case 'Third Departure':
          value = 'From'
          break
        case 'Third Arrival':
          value = 'To'
          break
        case 'carDrop':
          value = 'Drop Location (optional)'
          break
        case 'carDropLarge':
          value = 'Drop Location (optional)'
          break
        case 'carPickup':
          value = 'Pick Location'
          break
        case 'hotel':
          value = 'Destination'
          break
        case 'airline':
          value = 'Add Preferred Airline'
          break
        default:
          break
      }
      return value
    },
    secondDeparture () {
      return this.getSecondDeparture
    },
    thirdDeparture () {
      return this.getThirdDeparture
    }
  },
  watch: {
    search (val) {
      val && val !== this.value && this.querySelections(val)
    },
    secondDeparture (val) {
      if (this.type === 'Second Departure' && val && !this.$store.state.airports.departureAirports[1]) {
        this.search = val.Code
      }
    },
    thirdDeparture (val) {
      if (this.type === 'Third Departure' && val && !this.$store.state.airports.departureAirports[2]) {
        this.search = val.Code
      }
    }
  },
  methods: {
    selectAirport (value) {
      this.iconColor = '#757575'
      this.value = value
      if (this.type === 'hotel') value ? this.select = value.name : this.select = ''
      else if (this.type === 'airline') value ? this.select = `${value.name}, ${value.code}` : this.select = ''
      else this.select = value ? `${value.Name}, ${value.Code}` : ''
      switch (this.type) {
        case 'Departure':
          this.$store.dispatch('setAirports', { data: value, type: 'departure1' })
          break
        case 'Arrival':
          this.$store.dispatch('setAirports', { data: value, type: 'arrival1' })
          this.$emit('setSecondDeparture', value)
          break
        case 'Second Departure':
          this.$store.dispatch('setAirports', { data: value, type: 'departure2' })
          break
        case 'Second Arrival':
          this.$store.dispatch('setAirports', { data: value, type: 'arrival2' })
          this.$emit('setThirdDeparture', value)
          break
        case 'Third Departure':
          this.$store.dispatch('setAirports', { data: value, type: 'departure3' })
          break
        case 'Third Arrival':
          this.$store.dispatch('setAirports', { data: value, type: 'arrival3' })
          break
        case 'carPickup':
          this.$store.dispatch('setAirports', { data: value, type: 'carPickup' })
          break
        case 'carDrop':
          this.$store.dispatch('setAirports', { data: value, type: 'carDrop' })
          break
        case 'carDropLarge':
          this.$store.dispatch('setAirports', { data: value, type: 'carDrop' })
          break
        case 'hotel':
          this.$store.dispatch('setHotelDestination', value)
          break
        case 'airline':
          this.$store.dispatch('setPreferredAirline', value)
          break
        default:
          break
      }
      this.items = []
    },
    coloringIcon () {
      this.iconColor = 'secondary'
    },
    searchAirports (id) {
      const key = event.keyCode || event.charCode
      if (key !== 8) {
        this.search = document.getElementById(id).value
      } else if (!document.getElementById(id).value) {
        this.selectValue(null)
      }
    },
    selectValue (value) {
      this.selectAirport(value)
    },
    querySelections (input) {
      if (input.length < 1) return []
      else {
        if (this.type === 'hotel') {
          this.$http.get(getCities(input), { headers: headersNoAuth() }).then(response => response.json()).then(response => {
            this.items = response
            if (response.length === 1) this.selectValue(response[0])
            else {
              if (this.firstRequest && this.$store.state.hotelSearch.destination) {
                for (let index = 0; index < response.length; index++) {
                  if (response[index].code === this.$store.state.hotelSearch.destination.code) this.select = response[index]
                }
              }
              this.firstRequest = false
            }
            return this.items
          })
        } else if (this.type === 'airline') {
          this.$http.get(getAirlines(input), { headers: headersNoAuth() }).then(response => response.json()).then(response => {
            this.items = response
            if (response.length === 1) this.selectValue(response[0])
            else {
              if (this.firstRequest && this.$store.state.preferredAirline) {
                for (let index = 0; index < response.length; index++) {
                  if (response[index].code === this.$store.state.preferredAirline.code) this.select = response[index]
                }
              }
              this.firstRequest = false
            }
            this.loading = false
          }, () => {
            this.loading = false
          })
        } else {
          this.$http.get(getAirports(input), { headers: headersNoAuth() }).then(response => response.json()).then(response => {
            this.items = response
            if (response.length === 1) {
              this.selectValue(response[0])
            } else {
              if (this.firstRequest) {
                for (let index = 0; index < response.length; index++) {
                  if (response[index].Code === this.initialValue.split(',')[1]) this.selectValue(response[index])
                }
              } else {
                for (let index = 0; index < response.length; index++) {
                  if (this.secondDeparture && response[index].Code === this.secondDeparture.Code && this.type === 'Second Departure') this.selectValue(response[index])
                  if (this.thirdDeparture && response[index].Code === this.thirdDeparture.Code && this.type === 'Third Departure') this.selectValue(response[index])
                }
              }
              this.firstRequest = false
            }
          }).then(() => (this.loading = false))
        }
      }
    }
  },
  created () {
    document.addEventListener('click', () => {
      this.items = []
    })
  },
  mounted () {
    if (this.initialValue && this.type !== 'hotel' && this.type !== 'airline') this.search = this.initialValue.split(',')[1]
    if (this.$store.state.hotelSearch.destination && this.type === 'hotel') {
      this.selectAirport(this.$store.state.hotelSearch.destination)
    }
    if (this.$store.state.preferredAirline && this.type === 'airline') {
      this.selectAirport(this.$store.state.preferredAirline)
    }
  }
}
</script>

<style>
  .v-autocomplete__content {
    margin-left: 16px;
  }
  .airports-input {
    background-color: white;
    height: 52px;
    border: none;
    padding-left: 10px;
    padding-top: 5px;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0px 2px 2px grey;
    width: 99%;
    display: flex;
  }
  .airports-input input {
    width: 85%;
    margin-left: 10px;
    margin-bottom: 5px;
    padding-left: 10px;
    outline: none;
  }
  .airports-list {
    max-height: 300px;
    background-color: white;
    box-shadow: 0px 1px 1px grey;
    color: #333;
    z-index: 99;
    overflow: auto;
    /* padding-right: 10px; */
    position: absolute;
    min-width: 245px;
  }
  .airports-list ul {
    list-style: none;
    padding-left: 0px !important;
  }
  .airports-list ul li {
    padding: 5px 10px;
    margin: 5px auto;
  }
  .airports-list ul li:hover {
    cursor: pointer;
    background-color: rgb(233, 233, 233);
  }
  .airlines {
    /* background-color:transparent; */
    border: none;
    border-bottom: 1px solid white;
    box-shadow: none;
  }

</style>
