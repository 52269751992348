<template>
    <div>
        <v-card class="mb-5">
            <v-simple-table>
                <template v-slot:default>
                    <tr>
                      <td class="cell"><v-btn text color="blue" @click="getAllFlights">All Fares</v-btn></td>
                      <td class="cell" v-for="(item, i) in filters" :key="i" style="text-align: center; padding-top: 2px;">
                        <v-tooltip bottom color="secondary">
                          <template v-slot:activator="{ on }">
                            <img v-on="on" @click="getDirectAirlines(item.AirlineCode)" :src="item.AirlineLogo" width="50"/>
                          </template>
                          <span>{{item.AirlineName}}</span>
                        </v-tooltip>
                      </td>
                    </tr>
                    <tr v-if="none > 0">
                      <td class="cell"><v-btn text small color="blue" @click="getDirectFlights">Non-stop</v-btn></td>
                      <td class="cell" v-for="(item, i) in filters" :key="i" >
                        <div v-if="item.numberOfStops === 0">
                            <v-btn text small color="blue" @click="getDirectPrice(item.flightPrice, 0)">{{item.currency === 'USD' ? '$' : item.currency}} {{item.flightPrice}}</v-btn>
                        </div>

                        <div v-else>
                          <p>--</p>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="one > 0">
                      <td class="cell"><v-btn text small color="blue" @click="getOneStopFlights">one-stop</v-btn></td>
                      <td class="cell" v-for="(item, i) in filters" :key="i" >

                        <div v-if="item.numberOfStops === 1">
                            <v-btn text small color="blue" @click="getDirectPrice(item.flightPrice, 1)">{{item.currency === 'USD' ? '$' : item.currency}} {{item.flightPrice}}</v-btn>
                        </div>

                        <div v-else>
                          <p>--</p>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="multi > 0">

                      <td class="cell"><v-btn text small color="blue" @click="getMultiStopFlights">Multi-stop</v-btn></td>
                      <td class="cell" v-for="(item, i) in filters" :key="i" >

                        <div v-if="item.numberOfStops > 1">
                            <v-btn text small color="blue" @click="getDirectPrice(item.flightPrice, 2)">{{item.currency === 'USD' ? '$' : item.currency}} {{item.flightPrice}}</v-btn>
                        </div>
                        <div v-else>
                          <p>---</p>
                        </div>
                      </td>
                    </tr>
                </template>
            </v-simple-table>
        </v-card>
    </div>
</template>

<script>
export default {
  props: ['netMatrix', 'alterMatrix'],
  data () {
    return {
      multi: 0,
      none: 0,
      one: 0
    }
  },
  computed: {
    filters () {
      const filter = this.$store.state.flightResults.FilterMatrix
      this.netMatrix.forEach(item => {
        filter.push(item)
      })
      this.alterMatrix.forEach(item => {
        filter.push(item)
      })
      filter.sort((a, b) => {
        return a.flightPrice - b.flightPrice
      })
      this.updateMatrix(filter)
      return filter
    },
    flights () {
      return this.$store.state.flightResults.flights
    }
  },
  methods: {
    updateMatrix (filter) {
      this.multi = 0
      this.none = 0
      this.one = 0
      filter.forEach(item => {
        if (item.numberOfStops === 1) this.one = this.one + 1
        if (item.numberOfStops === 0) this.none = this.none + 1
        if (item.numberOfStops > 1) this.multi = this.multi + 1
      })
    },
    getAllFlights () {
      this.getUniqueArray(this.flights)
    },
    getDirectAirlines (airline) {
      const flights = this.matchAirline(airline)
      this.getUniqueArray(flights)
    },
    getDirectFlights () {
      const flights = this.matchStops(0, this.flights)
      this.getUniqueArray(flights)
    },
    getOneStopFlights () {
      const flights = this.matchStops(1, this.flights)
      this.getUniqueArray(flights)
    },
    getMultiStopFlights () {
      const flights = this.matchStops(2, this.flights)
      this.getUniqueArray(flights)
    },
    getDirectPrice (price, stops) {
      const matching = this.matchPrice(price)
      let filtered = []
      if (stops === 0) filtered = this.matchStops(0, matching)
      else if (stops === 1) filtered = this.matchStops(1, matching)
      else if (stops === 2) filtered = this.matchStops(2, matching)
      this.getUniqueArray(filtered)
    },
    matchStops (stops, flights) {
      const matchingStops = []
      flights.forEach(flight => {
        flight.flightSegments.forEach(flightSegment => {
          if (stops === 2) {
            if (flightSegment.stops >= stops) {
              matchingStops.push(flight)
            }
          } else {
            if (flightSegment.stops === stops) {
              matchingStops.push(flight)
            }
          }
        })
      })
      return matchingStops
    },
    matchAirline (airline) {
      const matchingAirs = []
      this.flights.forEach(flight => {
        flight.flightSegments.forEach(flightSegment => {
          flightSegment.Segments.forEach(segment => {
            if (segment.MarketingAirlineCode === airline) {
              matchingAirs.push(flight)
            }
          })
        })
      })
      return matchingAirs
    },
    matchPrice (price) {
      const matchingPrice = []
      let flightPrice
      this.flights.forEach(flight => {
        flightPrice = flight.pricingInfo.newFare > 0 ? flight.pricingInfo.newFare : flight.pricingInfo.TotalFare
        if (flightPrice === price) {
          matchingPrice.push(flight)
        }
      })
      return matchingPrice
    },
    getUniqueArray (filtered) {
      const uniqueFlights = filtered.reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id)
        if (!x) {
          return acc.concat([current])
        } else {
          return acc
        }
      }, [])
      this.$emit('choose', uniqueFlights)
    }
  }
}
</script>

<style>
    .airlines-data {
      border-left: 0.1px solid #e3e3e3;
    }
    .cell {
      padding: 1px;
      text-align: center;
      border: 0.1px solid #e3e3e3;
    }
</style>
