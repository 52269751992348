<template>
    <div>
      <v-snackbar v-model="snackbar" top :timeout="5000" :color="color">
        {{text}}
        <v-spacer></v-spacer>
        <v-btn icon @click="snackbar = false"><v-icon color="white">mdi-close</v-icon></v-btn>
      </v-snackbar>
      <v-overlay>
          <v-card light class="pa-5 ma-1 text-center" min-width="350px">
            <v-card-text>
              <p class="body-1 font-weight-bold primary--text">Your search has expired, what do you want to do ?</p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-row justify="space-between">
                <v-col>
                  <v-btn tile color="secondary" @click="refresh" :loading="loading">Refresh</v-btn>
                </v-col>
                <v-col>
                  <v-btn tile color="secondary" @click="goHome">Search again</v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
      </v-overlay>
    </div>
</template>

<script>
import { searchFlights, carSearch, searchHotels, headersNoAuth } from '../links'

export default {
  data () {
    return {
      loading: false,
      snackbar: false,
      color: '',
      text: ''
    }
  },
  methods: {
    refresh () {
      if (this.$store.state.currentSearch === 'flights') this.request(localStorage.getItem('alternateSearchTerm'))
      else if (this.$store.state.currentSearch === 'cars') this.request(localStorage.getItem('carsSearchTerm'))
      else if (this.$store.state.currentSearch === 'hotels') this.request(localStorage.getItem('hotelsSearchTerm'))
    },
    request (data) {
      this.loading = true
      if (this.$store.state.currentSearch === 'flights') {
        this.$http.get(searchFlights(data), { headers: headersNoAuth() }).then(response => response.json()).then(response => {
          this.loading = false
          if (response.status === true) {
            this.$store.dispatch('setFlightResults', response.data)
            localStorage.setItem('alternateSearchTerm', data)
          } else {
            this.snackbar = true
            this.color = 'error'
            this.text = 'Something went wrong please return to search'
          }
        }, response => {
          this.loading = false
        }).then(() => {
          this.loading = false
          this.$store.dispatch('setFlightsTimeOut', false)
          this.$store.dispatch('setFlightsTimeOutFunction', setTimeout(() => {
            this.$store.dispatch('setFlightsTimeOut', true)
          }, 20 * 60 * 1000)
          )
          this.$store.dispatch('setShowFlightBook', false)
        })
      } else if (this.$store.state.currentSearch === 'cars') {
        this.$http.get(carSearch(data), { headers: headersNoAuth() }).then(response => {
          if (response.body.status === 500 || response.body.status === false) {
            this.loading = false
            this.snackbar = true
            this.color = 'error'
            this.text = 'No Results to show'
          } else {
            this.loading = false
            this.$store.dispatch('setCars', response.body.data)
            this.$store.dispatch('setCarsTimeOut', false)
            this.$store.dispatch('setCarsSearchTimeOutFunction', setTimeout(() => {
              this.$store.dispatch('setCarsTimeOut', true)
            }, 20 * 60 * 1000)
            )
            this.$store.dispatch('setShowCarBook', false)
          }
        })
      } else {
        this.$http.get(searchHotels(data), { headers: headersNoAuth() }).then(response => {
          if (response.body.status === false) {
            this.snackbar = true
            this.color = 'error'
            this.text = response.body.message
            this.loading = false
          } else {
            this.loading = false
            this.$store.dispatch('setHotels', response.body.data)
            this.$store.dispatch('setHotelTimeOut', false)
            this.$store.dispatch('setHotelSearchTimeoutFunction', setTimeout(() => {
              this.$store.dispatch('setHotelTimeOut', true)
            }, 20 * 60 * 1000)
            )
            this.$store.dispatch('setShowHotelResults', true)
            this.$store.dispatch('setShowHotelBook', false)
            this.$store.dispatch('setShowOneHotel', false)
          }
        }, () => {
          this.loading = false
        })
      }
    },
    goHome () {
      if (this.$store.state.currentSearch === 'flights') {
        this.$store.dispatch('setShowFlightBook', false)
        this.$store.dispatch('setShowFlightResults', false)
        this.$store.dispatch('setFlightsTimeOut', false)
      } else if (this.$store.state.currentSearch === 'cars') {
        this.$store.dispatch('setShowCarBook', false)
        this.$store.dispatch('setShowCarResults', false)
        this.$store.dispatch('setCarsTimeOut', false)
      } else {
        this.$store.dispatch('setShowHotelResults', false)
        this.$store.dispatch('setShowHotelBook', false)
        this.$store.dispatch('setShowOneHotel', false)
        this.$store.dispatch('setHotelTimeOut', false)
      }
    }
  }
}
</script>
