import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import VueResource from 'vue-resource'
import VueGoodWizard from 'vue-good-wizard'
import vueCustomElement from 'vue-custom-element'
import 'document-register-element/build/document-register-element'
import { currencies } from './links.js'

Vue.prototype.window = window

Vue.config.productionTip = false

const url = currencies
let currenciesList
fetch(url).then(response => response.json()).then(response => {
  if (response.status) {
    currenciesList = response.data
  }
//   if (process.env.VUE_APP_DEFAULT_CURRENCY) {
//     store.dispatch('setCurrency', response.data.find(item => item.code === process.env.VUE_APP_DEFAULT_CURRENCY))
//   }
})

Vue.filter('changeCurrency', function (price) {
  //   const defaultRate = store.state.currencies.find(item => item.code === process.env.VUE_APP_DEFAULT_CURRENCY) || 1
  const currency = currenciesList.find(item => item.rate === 1)
  //   const finalPrice = (price / defaultRate.rate) * store.state.currency.rate
  return currency.code + ' ' + price.toFixed(2)
})

Vue.filter('formatDate', function (date) {
  date.replace(/-/g, '/')
  return new Date(date).toDateString()
})

Vue.use(VueResource)
Vue.use(VueGoodWizard)
Vue.use(vueCustomElement)

App.store = store
App.vuetify = vuetify
Vue.customElement('booking-engine', App)

// new Vue({
//   store,
//   vuetify,
//   render: h => h(App)
// }).$mount('#app')
