<template>
    <div>
        <v-progress-linear
            indeterminate
            color="secondary"
            height="10"
            top
            :active="loaded"
        ></v-progress-linear>
        <v-snackbar v-model="snackbar" :color="color" top :timeout="5000">
          <v-row justify="space-between">
            {{ text }}
            <v-btn
              dark
              icon
              @click="snackbar = false"
            >
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-snackbar>
        <v-overlay :value="showOverlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-container v-if="loaded === false && flight === null">
           <v-row justify='center' class="pt-5 mt-5"><p class="display-1 primary--text">This flight is not available, please seach again in few minutes.</p></v-row>
        </v-container>
        <v-container style="height: 95vh;" fluid v-if="loaded === false && flight !== null">
            <v-row v-if="flight !== null">
                <v-col cols="12" style="padding: 0px;">
                  <vue-good-wizard
                  :steps="steps"
                  :onNext="nextClicked"
                  :onBack="backClicked"
                  color="secondary"
                  finalStepLabel="Finish"
                  :nextStepLabel="nextLabel"
                  ref="myWizard"
                  >
                    <div slot="page1" color="secondary">
                      <v-row>
                        <v-col cols="12">
                          <flight-card :flights="flightCard"/>
                        </v-col>
                        <v-col cols="12">
                          <v-card outlined class="elevation-0 pa-5">
                            <v-card-title class="primary--text font-weight-bold">Pricing summary</v-card-title>
                            <v-card-text>
                              <p v-for="(item, i) in flight.pricingInfo.breakDowns" :key="i" class="body-1 primary--text mb-0">
                                {{item.passengerQuantity}}
                                <template v-for="type in types">
                                  <span :key="type.code" v-if="type.code === item.passengerType">{{type.label}}</span>
                                </template>(s) basic fare <strong>{{flight.pricingInfo.TotalFare_CurrencyCode === 'USD' ? '$' : flight.pricingInfo.TotalFare_CurrencyCode}} {{item.baseFare}}</strong> with <strong>{{flight.pricingInfo.TotalFare_CurrencyCode === 'USD' ? '$' : flight.pricingInfo.TotalFare_CurrencyCode}} {{item.taxes}}</strong> taxes  for person ><strong>Total {{item.TotalFare_CurrencyCode === 'USD' ? '$' : item.TotalFare_CurrencyCode}} {{item.totalFare}}</strong>
                              </p>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </div>
                    <div slot="page2">
                      <v-row>
                        <v-form v-model="valid1" ref="form1">
                        <p class="body-1 font-weight-bold secondary--text">Contact person details</p>
                        <v-container fluid>
                            <v-row>
                              <v-col cols="12" sm="6" md="3">
                                  <v-text-field
                                      outlined
                                      v-model="name"
                                      :rules="[v => (!!v && v.length > 3) || 'Item is required at least 3 characters', v => /^[_A-z]*((-|\s)*[_A-z])*$/.test(v) || 'Name Must be letters only with no spaces']"
                                      label="Full Name"
                                      required

                                  ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                  <!-- <v-text-field
                                      outlined
                                      v-model="phone"
                                      :rules="phoneRules"
                                      class="remove-controls"
                                      label="Phone"
                                      type="number"
                                      required
                                      autocomplete="false"

                                  ></v-text-field> -->
                                  <phoneInput @update="assignPhone" />
                              </v-col>
                              <v-col cols="12" sm="12" md="5">
                                  <v-text-field
                                      outlined
                                      v-model="email"
                                      :rules="emailRules"
                                      label="E-mail"
                                      required

                                  ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row v-for="n in passengersNum" :key="n">
                                <v-col cols="12" md="2" class="py-0 my-0">
                                    <p class="body-1 secondary--text">
                                      passenger {{n}} details, {{adultsNum >= n ? 'Adult' : (childrenNum >= ( n - adultsNum) ? 'Child' : (infantsNum >= (n - (adultsNum + childrenNum)) ? 'Infant' : null))}}
                                    </p>
                                </v-col>
                                <v-col cols="12" :sm="adultsNum >= n ? 6 : 4" md="2" class="py-0 my-0">
                                  <v-select
                                    v-model="passengerGender[n-1]"
                                    :items="['Male', 'Female']"
                                    label="Gender"
                                    outlined
                                    prepend-inner-icon="mdi-gender-male-female"
                                    required
                                    class="pa-0"
                                    height="56px"

                                    >
                                      <template v-slot:selection="{ item }">
                                        <span class="primary--text caption">{{item}}</span>
                                      </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="2" v-if="adultsNum >= n" class="my-0 py-0">
                                    <v-combobox
                                      v-model="passengerTitle[n-1]"
                                      :items="['Mr', 'Mrs', 'Ms', 'Miss']"
                                      :search-input.sync="search[n-1]"
                                      hide-selected
                                      hint="Add title and press enter to append it"
                                      label="Title"
                                      outlined
                                      prepend-inner-icon="mdi-card-account-details"
                                      :rules="requiredRule"
                                      class="pa-0"
                                      height="56px"

                                    >
                                      <template v-slot:no-data>
                                        <v-list-item>
                                          <v-list-item-content>
                                            <v-list-item-title>
                                              No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                      </template>
                                    </v-combobox>
                                </v-col>
                                <v-col cols="12" :sm="adultsNum >= n ? 8 : 8" :md="adultsNum >= n ? 4 : 6" class="my-0 py-0">
                                  <v-row>
                                    <v-col cols="12" sm="6">
                                      <v-text-field
                                      outlined
                                      prepend-inner-icon="mdi-account"
                                      v-model="passengerFirstName[n-1]"
                                      :rules="nameRules"
                                      label="First name"
                                      required
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                      <v-text-field
                                      outlined
                                      v-model="passengerLastName[n-1]"
                                      prepend-inner-icon="mdi-account"
                                      :rules="nameRules"
                                      label="Last name"
                                      required
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12" sm="4" md="2" class="my-0 py-0">
                                  <div :class="(validFormBirthday === false && dateOfBirth[n - 1] === undefined) ? 'error-range-input' : 'picker-cont'">
                                    <v-icon :color="(validFormBirthday === false && dateOfBirth[n - 1] === undefined) ? 'error' : '#757575'">mdi-calendar</v-icon>
                                    <date-range-picker
                                    class="range-input"
                                    ref="picker"
                                    opens="left"
                                    show-dropdowns
                                    :ranges="false"
                                    single-date-picker
                                    :locale-data="{
                                      direction: 'ltr',
                                      format: 'mm/dd/yyyy',
                                      separator: ' - ',
                                      applyLabel: 'Apply',
                                      cancelLabel: 'Cancel',
                                      weekLabel: 'W',
                                      customRangeLabel: 'Custom Range',
                                      daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                                      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                      firstDay: 1
                                    }"
                                    :maxDate="adultsNum >= n ? adultsMaxDate : ((childrenNum >= (n - adultsNum)) ? childrenMaxDate :  new Date().toISOString().substr(0, 10))"
                                    :minDate="adultsNum >= n ? '1930-01-01' : ((childrenNum >= (n - adultsNum)) ? childrenMinDate : infantsMinDate)"
                                    :autoApply="true"
                                    v-model="range[n-1]"
                                    @toggle="birthdayIndex = n-1; validFormBirthday = false"
                                    @select="setBirthDates"
                                    >
                                      <template v-slot:input="picker" style="min-width: 350px;">
                                        <span class="body-2 text-no-wrap">{{ picker.startDate ? picker.startDate.toString().substring(4, 15) : 'Birthday' }}</span>
                                      </template>
                                    </date-range-picker>
                                  </div>
                                  <p style="display: block;" v-if="(validFormBirthday === false && dateOfBirth[n - 1] === undefined)" class="caption error--text">Please fill this field</p>
                                </v-col>
                                <v-col cols="12" sm="4" md="4" class="my-0 py-0">
                                    <v-text-field
                                        v-model="passNum[n-1]"
                                        @focus="addValue(n-1)"
                                        label="Passport number"
                                        prepend-inner-icon="mdi-passport"
                                        :rules="[() => !!passNum[n-1] || 'This field is required', () => /^[a-z0-9A-Z0-9]*$/.test(passNum[n-1]) || 'No special characters allowed', () => passNum[n-1].length >= 5 || 'Passport number must be at least 5 characters', () => passNum[n-1].length <= 15 || 'Passport number must be at most 15 characters']"
                                        outlined
                                        color="#757575"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" md="4" lg="4" class="my-0 py-0">
                                  <div :class="(validFormPassport === false && dateOfBirth[n - 1] === undefined) ? 'error-range-input' : 'picker-cont'">
                                    <v-icon :color="(validFormPassport === false && passExpireDate[n - 1] === undefined) ? 'error' : '#757575'">mdi-calendar</v-icon>
                                    <date-range-picker
                                    class="range-input pass-range-input"
                                    ref="passportPicker"
                                    opens="center"
                                    :ranges="false"
                                    single-date-picker
                                    :locale-data="{
                                      direction: 'ltr',
                                      format: 'mm/dd/yyyy',
                                      separator: ' - ',
                                      applyLabel: 'Apply',
                                      cancelLabel: 'Cancel',
                                      weekLabel: 'W',
                                      customRangeLabel: 'Custom Range',
                                      daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                                      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                      firstDay: 1
                                    }"
                                    show-dropdowns
                                    :minDate="minExpireDate"
                                    :autoApply="true"
                                    v-model="passportRange[n-1]"
                                    @toggle="passportIndex = n-1; expire(n-1); validFormPassport = false"
                                    @select="setPassport"
                                    >
                                      <template v-slot:input="passportPicker" style="min-width: 350px;">
                                        <span class="body-2 text-no-wrap">{{ passportPicker.startDate ? passportPicker.startDate.toString().substring(4, 15) : 'Passport Expire Date' }}</span>
                                      </template>
                                    </date-range-picker>
                                  </div>
                                  <p style="display: block;" v-if="(validFormPassport === false && passExpireDate[n - 1] === undefined)" class="caption error--text">Please fill this field</p>
                                </v-col>
                                <v-col cols="12" sm="4" md="4" lg="4" class="my-0 py-0">
                                    <v-autocomplete
                                    v-model="issueCountry[n-1]"
                                    :rules="[() => !!issueCountry[n-1] || 'This field is required']"
                                    :items="countries"
                                    item-text="name"
                                    item-value="code"
                                    placeholder="Issuing Country"
                                    name="issue-country-for-passports"
                                    required
                                    outlined
                                    prepend-inner-icon="mdi-flag"
                                    hide-no-data

                                    autocomplete="off"
                                    :menu-props="{ auto: true, maxWidth: 200, overflowY: false }"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" v-if="n < passengersNum">
                                  <v-divider class="my-3"></v-divider>
                                </v-col>
                            </v-row>
                          </v-container>
                        </v-form>
                      </v-row>
                    </div>
                    <div slot="page3">
                      <bookinData type="flights" :items="travellersData" />
                      <v-divider></v-divider>
                        <v-form v-model="valid2" ref="form2">
                          <address-card class="mb-5" />
                          <credit v-if="paymentType === 'credit'" @card="setCardData" @expire="setCardExpiry" @cvv="setCVV" />
                        </v-form>
                      <v-divider></v-divider>
                      <v-row>
                          <v-col cols="12">
                              <v-row justify="space-between" align="end">
                                  <v-col cols="12">
                                    <v-card outlined class="elevation-0 pa-5">
                                      <v-card-title class="primary--text font-weight-bold">Pricing summary</v-card-title>
                                      <v-card-text>
                                        <p v-for="(item, i) in flight.pricingInfo.breakDowns" :key="i" class="body-1 primary--text mb-0">
                                          {{item.passengerQuantity}}
                                          <template v-for="type in types">
                                            <span :key="type.code" v-if="type.code === item.passengerType">{{type.label}}</span>
                                          </template>(s) basic fare <strong>{{flight.pricingInfo.TotalFare_CurrencyCode === 'USD' ? '$' : flight.pricingInfo.TotalFare_CurrencyCode}}{{item.baseFare}}</strong> with <strong>{{flight.pricingInfo.TotalFare_CurrencyCode === 'USD' ? '$' : flight.pricingInfo.TotalFare_CurrencyCode}}{{item.taxes}}</strong> taxes  for person ><strong>Total {{flight.pricingInfo.TotalFare_CurrencyCode === 'USD' ? '$' : flight.pricingInfo.TotalFare_CurrencyCode}}{{item.totalFare}}</strong>
                                        </p>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                  <v-col cols="6">
                                      <p class="body-1 font-weight-bold primary--text">Total</p>
                                      <p class="body-2 font-weight-regular grey--text text-wrap">
                                          By clicking Confirm & Book button you agree with terms and conditionals and money back gurantee. Thank you for trusting our service.
                                      </p>
                                  </v-col>
                                  <v-col cols="6">
                                      <p class="display-1 font-weight-bold primary--text text-right">{{ flight.pricingInfo.TotalFare_CurrencyCode === 'USD' ? '$' : flight.pricingInfo.TotalFare_CurrencyCode }} {{flight.pricingInfo.newFare > 0 ? flight.pricingInfo.newFare : flight.pricingInfo.TotalFare}}</p>
                                  </v-col>
                              </v-row>
                          </v-col>
                      </v-row>
                    </div>
                    <div slot="page4">
                      <h3 class="display-1 font-weight-thin primary--text">Your flight was booked successfully, we will send you an email with more details.</h3>
                      <h3 class="body-1 font-weight-bold primary--text mt-5">Your Booking Reference is {{pnr}}</h3>
                      <h3 class="body-1 font-weight-bold primary--text">Total price is {{ currency === 'USD' ? '$' : currency }}{{totalPrice}}</h3>
                    </div>
                  </vue-good-wizard>
                </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12">
                <p class="body-1 primary--text">This flight is not available, please seach again.</p>
              </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { getFlight, getAirports, createPNR, headersNoAuth } from '../links'
import { countries } from '../contants'
import flightCard from '../components/flightCard.vue'
import bookinData from '../components/bookingDataTable'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import phoneInput from '../components/mobile.vue'
import credit from '../components/credit'
import addressCard from '../components/address info.vue'

export default {
  title: 'Fare33-Book',
  components: {
    flightCard,
    bookinData,
    DateRangePicker,
    phoneInput,
    credit,
    addressCard
  },
  data () {
    return {
      range: [],
      passportRange: [],
      birthdayIndex: null,
      passportIndex: null,
      steps: [
        {
          label: 'Review',
          slot: 'page1'
        },
        {
          label: 'Travellers',
          slot: 'page2'
        },
        {
          label: 'Payment & Booking',
          slot: 'page3'
        },
        {
          label: 'Done',
          slot: 'page4'
        }
      ],
      show: false,
      showOverlay: false,
      paymentType: 'credit',
      interval: null,
      nextLabel: 'Continue',
      menu: false,
      menu1: [],
      menu2: [],
      valid1: true,
      valid2: true,
      validFormBirthday: true,
      validFormPassport: true,
      loading: false,
      loaded: true,
      snackbar: false,
      color: '',
      text: '',
      flight: null,
      flightResults: null,
      departures: [],
      arrivals: [],
      departDates: [],
      arriveDate: null,
      passengers: {},
      name: '',
      nameRules: [v => (!!v && v.length > 1) || 'Item is required at least 2 characters',
        v => /^[A-Za-z][A-Za-z]*$/.test(v) || 'Name Must be letters only with no spaces'
      ],
      phone: '',
      phoneRules: [
        v => !!v || 'Phone is required',
        v => (!isNaN(v)) || 'Phone must be only numbers'
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(v) || 'E-mail must be valid'
      ],
      zipRules: [
        v => !!v || 'Zip code is required',
        v => (!isNaN(v)) || 'Zip code must be only numbers'
      ],
      zip: '',
      requiredRule: [
        v => !!v || 'Field is required'
      ],
      passRules: [
        v => !!v || 'Passport number is required',
        v => v.length <= 15 || 'Maximum number of characters is 15'
      ],
      // line: '',
      address: '',
      cardExpireDate: '',
      cardNum: '',
      cardRules: [
        v => !!v || 'Card number is required',
        v => (!isNaN(v)) || 'Card number must be only numbers',
        v => v.length === 16 || 'Card number must be 16 characters'
      ],
      cards: [
        { label: 'Visa', code: 'VI' },
        { label: 'Master Card', code: 'CA' },
        { label: 'American Express', code: 'AX' },
        { label: 'Discover', code: 'DS' },
        { label: 'Diners Club', code: 'DN' }
      ],
      cardCode: '',
      types: [
        { label: 'Adult', code: 'ADT' },
        { label: 'Child', code: 'CNN' },
        { label: 'Infant', code: 'INF' }
      ],
      passengerType: [],
      passengerTitle: [],
      passengerGender: [],
      search: [],
      passengerFirstName: [],
      passengerLastName: [],
      dateOfBirth: [],
      dateOfBirthText: [],
      passExpireDate: [],
      passExpireDateText: [],
      minExpireDate: '',
      passNum: [''],
      issueCountry: [],
      goNext: true,
      pnr: '',
      totalPrice: '',
      currency: '',
      currentPage: 0,
      travellersData: []
    }
  },
  computed: {
    flightCard () {
      const flights = []
      flights.push(this.flight)
      return flights
    },
    countries () {
      return countries
    },
    adultsNum () {
      return Number(this.passengers.adults)
    },
    childrenNum () {
      return Number(this.passengers.children)
    },
    infantsNum () {
      return Number(this.passengers.infants)
    },
    passengersNum () {
      return Number(this.passengers.adults) + Number(this.passengers.children) + Number(this.passengers.infants)
    },

    // compute passengers valid birth range
    adultsMaxDate () {
      const today = new Date()
      const max = `${today.getFullYear() - 12}-${today.getMonth() + 1}-${today.getDate() - 1}`
      return max
    },
    childrenMaxDate () {
      const today = new Date()
      const max = `${today.getFullYear() - 2}-${today.getMonth() + 1}-${today.getDate() - 1}`
      return max
    },
    childrenMinDate () {
      const today = new Date()
      const min = `${today.getFullYear() - 12}-${today.getMonth() + 1}-${today.getDate() - 1}`
      return min
    },
    infantsMinDate () {
      const today = new Date()
      const min = `${today.getFullYear() - 2}-${today.getMonth() + 1}-${today.getDate() - 1}`
      return min
    },
    cardExpireDateText () {
      if (this.cardExpireDate) return this.formatDate(this.cardExpireDate, 0, 'expire')
      else return ''
    }
  },
  watch: {
    menu1 (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    currentPage (newVal) {
      if (newVal === 1) {
        setTimeout(() => {
          document.getElementById('card-address').addEventListener('input', (e) => {
            this.$store.dispatch('setWrittenAddress', e.target.value)
          })
        }, 500)
      }
    }
  },
  methods: {
    assignPhone (phone) {
      this.phone = phone
    },
    setCardData (number, type) {
      this.cardNum = number
      switch (type) {
        case 'visa':
          this.cardCode = 'VI'
          break
        case 'mastercard':
          this.cardCode = 'CA'
          break
        case 'american-express':
          this.cardCode = 'AX'
          break
        case 'discover':
          this.cardCode = 'DS'
          break
        case 'diners-club':
          this.cardCode = 'DN'
          break
        default:
          this.cardCode = type
          break
      }
    },
    setCardExpiry (date) {
      this.cardExpireDate = date
    },
    setCVV (cvv) {
      this.cvv = cvv
    },
    setBirthDates (date) {
      this.dateOfBirth[this.birthdayIndex] = date.startDate.toISOString().substr(0, 10)
    },
    setPassport (date) {
      this.passExpireDate[this.passportIndex] = date.startDate.toISOString().substr(0, 10)
    },
    formatDate (date, i, type) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const newDate = `${day} ${months[month - 1]} ${year}`
      if (day) type === 'passport' ? this.passExpireDateText[i] = newDate : this.dateOfBirthText[i] = newDate
      else return `${months[month - 1]} ${year}`
    },
    classType () {
      let type
      switch (this.passengers.classType) {
        case 'Y':
          type = 'Economy'
          break
        case 'C':
          type = 'Business'
          break
        case 'F':
          type = 'First'
          break
        case 'P':
          type = 'Premium Economy'
          break
        default:
          break
      }
      return type
    },
    addValue (n) {
      this.passNum[n] = ''
    },
    expire (n) {
      const lastFlightSegment = this.flight.flightSegments[this.flight.flightSegments.length - 1]
      const lastArrivalDate = lastFlightSegment.Segments[lastFlightSegment.Segments.length - 1].ArrivalDate
      const today = new Date(lastArrivalDate)
      today.setMonth(today.getMonth() + 6)
      today.setDate(today.getDate() + 1)
      // this.passExpireDate[n] = today.toISOString().substring(0, 10)
      this.minExpireDate = today.toISOString().substring(0, 10)
    },
    getProperDate (n) {
      if (!this.dateOfBirth[n - 1]) {
        if (this.adultsNum >= (n)) {
          this.dateOfBirth[n - 1] = this.adultsMaxDate
        } else if (this.childrenNum >= (n - this.adultsNum)) {
          this.dateOfBirth[n - 1] = this.childrenMaxDate
        } else if (this.infantsNum >= (n - (this.adultsNum + this.childrenNum))) {
          this.dateOfBirth[n - 1] = this.infantsMinDate
        }
      }
    },
    book () {
      const body = {
        flight_id: this.$store.state.flightId,
        search_id: this.$store.state.flightResults.search_id,
        contact_person_name: this.name,
        contact_phone: this.phone.nationalNumber,
        countryIsoCode: this.phone.countryCode,
        contact_email: this.email,
        zipCode: this.$store.state.zipCode,
        address: this.$store.state.writtenAddress,
        streetLine: this.streetLine,
        city: this.$store.state.writtenCity,
        state: this.$store.state.writtenState,
        countryISO: this.$store.state.writtenCountry,
        creditCardNumber: this.cardNum,
        creditCardExpireDate: this.cardExpireDate,
        creditCardType: this.cardCode,
        passengerDetails: this.travellersData
      }
      const header = { headers: headersNoAuth() }
      this.$http.post(createPNR, body, header).then(response => {
        if (response.body.status === false) {
          this.showOverlay = false
          this.text = response.body.message
          this.color = 'error'
          this.goNext = false
          this.snackbar = true
        } else {
          this.showOverlay = false
          this.pnr = response.data.data.PNR
          this.totalPrice = response.data.data.totalPrice
          this.currency = response.data.data.priceCurrency
          this.$store.dispatch('setFlightsTimeOut', false)
          localStorage.setItem('timeOut', false)
          if (this.$store.state.flightsTimeOutFunction) this.$store.dispatch('clearFlightsTimeoutFunction')
          if (this.$store.state.flightsSearchTimeOutFunction) this.$store.dispatch('clearFlightsSearchTimeoutFunction')
          document.querySelector('.wizard__body__actions a[data-v-c21d83ca]').style.display = 'none'
          this.nextLabel = 'Finish'
          this.currentPage = 3
          this.$refs.myWizard.goTo(3)
        }
      }, () => {
        this.showOverlay = false
        this.snackbar = true
        this.goNext = false
        this.color = 'error'
        this.text = 'Something went wrong please try again in few minutes'
      })
    },
    prepareTravellersData () {
      this.travellersData = []
      for (let index = 0; index < this.passengersNum; index++) {
        let type
        let title
        let gender
        if (this.passengerGender[index]) {
          this.passengerGender[index] === 'Male' ? gender = 'M' : gender = 'F'
        }
        if (this.adultsNum >= (index + 1)) {
          type = 'ADT'
          title = this.passengerTitle[index]
          if (this.passengerGender[index] === undefined) {
            if (title === 'Mr') {
              this.passengerGender[index] = 'M'
              gender = 'M'
            } else if (['Mrs', 'Ms', 'Miss'].indexOf(title) !== -1) {
              this.passengerGender[index] = 'F'
              gender = 'F'
            }
          }
        } else if (this.childrenNum >= ((index + 1) - this.adultsNum)) {
          type = 'CNN'
          title = 'CHD'
        } else if (this.infantsNum >= ((index + 1) - (this.adultsNum + this.childrenNum))) {
          type = 'INF'
          title = 'INF'
        }
        this.travellersData.push({
          passengerType: type,
          passengerTitle: title,
          passengerGender: gender,
          passengerFirstName: this.passengerFirstName[index],
          passengerLastName: this.passengerLastName[index],
          date_of_birth: this.dateOfBirth[index],
          passport_number: this.passNum[index],
          passport_expire_date: this.passExpireDate[index],
          passport_issue_country: this.issueCountry[index]
        })
      }
    },

    // when next btn is clicked in stepper
    nextClicked (currentPage) {
      try {
        if (currentPage === 1) {
          this.currentPage = 1
          this.$refs.form1.validate()
          this.nextLabel = this.valid1 ? 'Book' : 'Continue'
          if (this.valid1) {
            const fullNames = []
            for (let index = 0; index < this.passengerFirstName.length; index++) {
              fullNames.push(`${this.passengerFirstName[index]} ${this.passengerLastName[index]}`)
            }
            if (new Set(fullNames).size === fullNames.length) {
              if (this.phone.valid) {
                this.snackbar = false
                this.prepareTravellersData()
                return true
              } else {
                this.snackbar = true
                this.color = 'error'
                this.text = 'Please provide a valid phone number'
                return false
              }
            } else {
              this.snackbar = true
              this.color = 'warning'
              this.text = 'Please avoid using similar names for passengers'
              return false
            }
          } else {
            this.snackbar = true
            this.color = 'warning'
            this.text = 'Please fill all fields to continue'
            this.validFormBirthday = false
            this.validFormPassport = false
            return false
          }
        } else if (currentPage === 2) {
          this.currentPage = 2
          this.$refs.form2.validate()
          if (this.valid2) {
            this.snackbar = false
            this.showOverlay = true
            this.book()
          } else {
            this.snackbar = true
            this.color = 'warning'
            this.text = 'Please fill all fields to Book'
            return false
          }
        } else if (currentPage === 3) {
          this.$store.dispatch('setShowFlightBook', false)
          this.$store.dispatch('setShowFlightResults', false)
          return true
        } else {
          this.nextLabel = 'Continue'
          return true
        }
      } catch (error) {}
    },
    // when back button is clicked in stepper
    backClicked (currentPage) {
      this.currentPage = currentPage
      if (currentPage === 4) {
        return false
      } else if (currentPage === 2) {
        this.nextLabel = 'Continue'
        return true
      } else return true
    }
  },
  created () {
    this.flightResults = this.$store.state.flightResults
    this.$store.state.airports.departureAirports.forEach(item => {
      if (item !== null) {
        this.$http.get(getAirports(item), { headers: headersNoAuth() }).then(response => {
          this.departures.push(response.body[0])
        })
      }
    })
    this.$store.state.airports.arrivalAirports.forEach(item => {
      if (item !== null) {
        this.$http.get(getAirports(item), { headers: headersNoAuth() }).then(response => {
          this.arrivals.push(response.body[0])
        })
      }
    })
    this.$store.state.flightDates.departureDates.forEach(item => {
      if (item !== null) {
        this.departDates.push(item)
      }
    })
    this.arriveDate = this.$store.state.flightDates.arrivalDate
    this.passengers = this.$store.state.passengers
    const passengersNum = Number(this.passengers.adults) + Number(this.passengers.children) + Number(this.passengers.infants)
    for (let i = 0; i < passengersNum; i++) {
      this.menu1[i] = false
      this.menu2[i] = false
      this.passNum.push('')
      this.range.push({})
      this.passportRange.push({})
    }
    const id = this.$store.state.flightId
    const search = this.$store.state.flightResults.search_id
    const relatedFlightId = this.$store.state.relatedFlightId
    let query
    if (relatedFlightId) {
      query = getFlight(id, search, relatedFlightId)
    } else {
      query = getFlight(id, search)
    }
    this.$http.get(query, { headers: headersNoAuth() }).then(response => {
      if (response.body.status === true) {
        this.flight = response.body.data
        this.loaded = false
      } else {
        this.loaded = false
        this.snackbar = true
        this.color = 'error'
        this.text = response.body.message
      }
    }, () => {
      this.loaded = false
      this.snackbar = true
      this.color = 'error'
      this.text = 'Something went wrong'
    })
  }
}
</script>

<style>
  .input-tel.lg .input-tel__input[data-v-e59be3b4], .country-selector.lg .country-selector__input[data-v-46e105de] {
    height: 56px !important;
    border: 1.2px solid #9e9e9e !important;
  }
  .wizard__body__actions a[data-v-c21d83ca] {
    background-color:#DF731C !important;
  }
  .wizard__body__actions[data-v-c21d83ca] {
    background-color:white !important;
    border-top: 0.1px solid #e3e3e3 !important;
  }
  .wizard__body[data-v-c21d83ca] {
    margin-top: 30px !important;
    min-height: auto !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .wizard__step.active .wizard__step__indicator[data-v-c21d83ca] {
    background-color:#DF731C !important;
  }
  .wizard__step.active:not(:first-child) .wizard__step__line[data-v-c21d83ca] {
    background-color:#DF731C !important;
  }
  [v-cloak] { display: none; }
  .remove-controls input[type='number'] {
    -moz-appearance:textfield;
  }
  .remove-controls input::-webkit-outer-spin-button,
  .remove-controls input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
  .picker-cont {
    width: 99%;
    height: 56px;
    border-radius: 5px;
    border: 0.5px solid #a6a6a6;
    padding: 10px 15px;
    display: flex;
  }
  .range-input {
    width: 90%;
  }
  .reportrange-text[data-v-267f4ee2] {
    border: none !important;
  }
  .daterangepicker.opensleft[data-v-267f4ee2] {
    transform: translateY(-20%) !important;
  }
  .daterangepicker.openscenter[data-v-267f4ee2] {
    transform: translate(-50%, -35%) !important;
  }
  .error-range-input {
    width: 99%;
    height: 56px;
    border-radius: 5px;
    border: 2px solid red;
    box-sizing: border-box;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 10px 15px;
    display: flex;
  }
  .address-autocomplete {
    width: 100%;
    height: 56px;
    outline: none;
    border: 0.5px solid #9e9e9e !important;
    border-radius: 5px;
    padding: 0 10px;
    margin-bottom: 30px;
  }
</style>
