<template>
    <div>
      <v-container fluid>
          <v-row>
              <v-col cols="12" sm="3" md="2">
                <carFilter @changed="filter"/>
              </v-col>
              <v-col cols="12" sm="9" md="10">
                <p class="body-1 primary--text font-weight-bold">{{cars.cars.length}} available from {{formatDate(cars.pickUpDate)}} at {{cars.pickUpTime}} to {{formatDate(cars.returnDate)}} at {{cars.returnTime}}</p>
                <p v-if="criteria" class="body-2 primary--text">From ${{criteria.prices[0]}} To ${{criteria.prices[1]}}, {{criteria.cat}}, {{criteria.seats}} seats by {{criteria.vendors}}</p>
                <carCard :cars="cars"></carCard>
              </v-col>
          </v-row>
      </v-container>
    </div>
</template>

<script>
import carCard from '../components/carCard'
import carFilter from '../components/carFilter'

export default {
  title: 'Fare33-Cars Search Results',
  components: {
    carCard,
    carFilter
  },
  data () {
    return {
      cars: null,
      criteria: null
    }
  },
  computed: {
    carsStore () {
      return this.$store.state.cars
    }
  },
  watch: {
    carsStore (newVal) {
      this.cars = newVal
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    filter (cars, criteria) {
      this.cars.cars = [...cars]
      this.criteria = criteria
    }
  },
  created () {
    this.$store.state.currentSearch = 'cars'
    this.cars = this.$store.state.cars
  }
}
</script>
