<template>
    <div>
      <v-container fluid>
          <v-row>
            <v-col cols="12" sm="3" md="2">
              <hotelFilters @changed="filter"/>
            </v-col>
            <v-col cols="12" sm="9" md="10">
              <p class="body-1 font-weight-bold primary--text ml-2" v-cloak>{{hotels.Hotels.length}} properities found</p>
              <hotelsCard :hotels="hotels" :session="hotels.session_id"></hotelsCard>
            </v-col>
          </v-row>
      </v-container>
    </div>
</template>

<script>
import hotelsCard from '../components/hotelCard'
import hotelFilters from '../components/hotelFilters'

export default {
  title: '-Hotels Search Results',
  components: {
    hotelsCard,
    hotelFilters
  },
  data () {
    return {
      hotelsNum: 0,
      city: '',
      hotels: null
    }
  },
  methods: {
    filter (hotels) {
      this.hotels.Hotels = [...hotels]
    }
  },
  computed: {
    hotelStore () {
      return this.$store.state.hotels
    },
    cityInStore () {
      return this.$store.state.hotelSearch.destination
    }
  },
  watch: {
    hotelStore (newVal) {
      this.hotels = newVal
    },
    cityInStore (newVal) {
      this.city = newVal
    }
  },
  created () {
    this.$store.state.currentSearch = 'hotels'
    this.city = this.$store.state.hotelSearch.destination
    this.hotels = this.$store.state.hotels
  }
}
</script>
