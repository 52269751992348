<template>
  <v-app class="main-cont">
    <!-- timer for bookings -->
    <overlay v-if="($store.state.flightsTimeOut && tab === 0) || ($store.state.carsTimeOut && tab === 1) || ($store.state.hotelTimeOut && tab === 2)"/>
    <v-card elevation="0" class="bg">
      <v-tabs
      v-model="tab"
      background-color="transparent"
      color="orange"
      class="tabs"
      >
        <v-tab
          v-for="item in items"
          :key="item.title"
        >
          <v-icon left>{{item.icon}}</v-icon>{{ item.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <flightSearch @scrollNow="scrollNow"/>
        </v-tab-item>
        <v-tab-item>
          <hotelSearch @scrollNow="scrollNow"/>
        </v-tab-item>
        <v-tab-item>
          <carSearch @scrollNow="scrollNow"/>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <flightResults style="transform: translateY(-150px);" v-if="$store.state.showFlightResults && tab === 0"/>
    <hotelsResults style="transform: translateY(-150px);" v-if="$store.state.showHotelResults && tab === 1"/>
    <oneHotel style="transform: translateY(-150px);" v-if="$store.state.showOneHotel && tab === 1"/>
    <carsResults style="transform: translateY(-150px);" v-if="$store.state.showCarResults && tab === 2"/>
    <v-dialog persistent v-model="$store.state.showFlightBook" scrollable width="100%" style="position: relative;">
      <v-card class="pb-0" color="#f8f8f8">
      <v-card-title class="mb-5">
      <v-btn
      @click="$store.dispatch('setShowFlightBook', false)"
      icon
      absolute
      top
      left
      >
        <v-icon color="secondary">mdi-close</v-icon>
      </v-btn>
      </v-card-title>
      <v-card-text class="mb-5">
      <book v-if="$store.state.showFlightBook"/>
      </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="$store.state.showCarBook" scrollable width="100%" style="position: relative;">
          <v-card class="pb-0">
          <v-card-title class="mb-5" background-color="#f8f8f8">
          <v-btn
          @click="$store.dispatch('setShowCarBook', false)"
          icon
          absolute
          top
          left
          >
            <v-icon color="secondary">mdi-close</v-icon>
          </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mb-0">
          <carBook v-if="$store.state.showCarBook"/>
          </v-card-text>
          </v-card>
    </v-dialog>
    <v-dialog persistent v-model="$store.state.showHotelBook" scrollable width="100%" style="position: relative;">
          <v-card class="pb-0">
          <v-card-title class="mb-5" background-color="#f8f8f8">
          <v-btn
          @click="$store.dispatch('setShowHotelBook', false)"
          icon
          absolute
          top
          left
          >
            <v-icon color="secondary">mdi-close</v-icon>
          </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mb-0">
          <hotelBook v-if="$store.state.showHotelBook"/>
          </v-card-text>
          </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import flightSearch from './views/flights.vue'
import carSearch from './views/cars.vue'
import hotelSearch from './views/hotels.vue'
import flightResults from './views/flightResults.vue'
import carsResults from './views/carsResults.vue'
import hotelsResults from './views/hotelsResults.vue'
import book from './views/book.vue'
import carBook from './views/carBook.vue'
import hotelBook from './views/hotelBook.vue'
import oneHotel from './views/oneHotel.vue'
import overlay from './components/overlay.vue'

export default {
  name: 'App',
  components: {
    flightSearch,
    overlay,
    flightResults,
    book,
    carSearch,
    carsResults,
    carBook,
    hotelSearch,
    hotelsResults,
    hotelBook,
    oneHotel
  },
  data () {
    return {
      tab: null,
      items: [
        { title: 'Flights', icon: 'mdi-airplane' },
        { title: 'Hotels', icon: 'mdi-domain' },
        { title: 'Cars', icon: 'mdi-car' }
      ]
    }
  },
  methods: {
    scrollNow () {
      const height = window.innerHeight
      this.$vuetify.goTo(height, { duration: 1000 })
    }
  },
  created () {
    this.$store.state.flightDates.departureDates = [null, null, null]
    this.$store.state.flightDates.arrivalDate = null
    this.$store.state.carInfo.pickup = null
    this.$store.state.carInfo.drop = null
    this.$store.state.hotelSearch.checkInDate = null
    this.$store.state.hotelSearch.checkOutDate = null
    // this.$http.get('http://ip-api.com/json').then(response => {
    //   this.$store.dispatch('setCountryCode', response.body.countryCode)
    // })
    this.$store.dispatch('setShowFlightResults', false)
    this.$store.dispatch('setShowFlightBook', false)
    this.$store.dispatch('clearFlightsSearchTimeoutFunction')
    this.$store.dispatch('clearFlightsTimeoutFunction')
    this.$store.dispatch('setFlightsTimeOut', false)
    this.$store.dispatch('setShowCarResults', false)
    this.$store.dispatch('setShowCarBook', false)
    this.$store.dispatch('setCarsTimeOut', false)
    this.$store.dispatch('clearCarsSearchTimeOutFunction')
    this.$store.dispatch('clearCarsTimeOutFunction')
    this.$store.dispatch('setShowHotelResults', false)
    this.$store.dispatch('setShowOneHotel', false)
    this.$store.dispatch('setShowHotelBook', false)
    this.$store.dispatch('setHotelTimeOut', false)
    this.$store.dispatch('clearHotelSearchTimeoutFunction')
    this.$store.dispatch('clearHotelTimeoutFunction')
  }
}
</script>

<style>
   #app {
    /* background: transparent !important; */
    /* background-color: tomato; */
  }
  input {
    outline: none;
  }
  .tabs {
    /* border-bottom: rgba(182, 18, 18, 0.993) 1px solid; */
    margin-top: 20px;
    transform: translateY(-5px);
  }
  .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active), .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon, .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn, .theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
    color: rgb(161, 161, 161) !important;
  }
  .bg {
    background-color: transparent !important;
    /* min-height: 500px; */
  }
  .v-window__container {
    min-height: 500px;
  }
  .v-window-item.v-window-item--active {
    background-color: #372b683b;
  }
  .v-window.v-item-group.theme--light.v-tabs-items {
    background-color: transparent;
  }
  .daterangepicker td.in-range {
    background-color: #93deff;
  }
  .daterangepicker td.active {
    background-color: #0086bf;
  }
  .dots-text:hover {
    background-color: rgb(230, 230, 230);
    color: blue;
  }
  @media screen and (max-width:945px) {
    .v-window__container {
      min-height: 100vh;
    }
  }
</style>
