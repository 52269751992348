<template>
  <div>
    <v-card outlined class="pa-5 my-5">
      <v-card-title>Payment</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="2">
              <v-text-field
              v-model="cvv"
              :rules="cvvRules"
              label="CVV"
              required
              outlined
              class="remove-controls"
              type="number"
              color="blue"
              @change="setCVV"
              ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
              <v-text-field
                  prepend-inner-icon="mdi-credit-card-outline"
                  v-model="cardNum"
                  :rules="cardRules"
                  label="Card number"
                  required
                  outlined
                  class="remove-controls"
                  type="number"
                  @change="changeCardNum"
                  color="blue"
                  :suffix="cardCode"
              ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
              <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="290px"
              >
                  <template v-slot:activator="{ on }">
                  <v-text-field
                      outlined
                      :rules="requiredRule"
                      label="Card expire Date"
                      readonly
                      color="blue"
                      v-on="on"
                      prepend-inner-icon="mdi-calendar"
                      v-model="cardExpireDateText"
                  ></v-text-field>
                  </template>
                  <v-date-picker
                  color="secondary"
                  :min="new Date().toISOString().substr(0, 10)"
                  v-model="cardExpireDate"
                  type="month"
                  @input="menu = false; changeExpiry()"
                  ></v-date-picker>
              </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import cardType from 'credit-card-type'

export default {
  data () {
    return {
      cvv: '',
      cardNum: '',
      cardExpireDate: '',
      cardCode: '',
      menu: false,
      validCard: true,
      cardRules: [
        v => !!v || 'Card number is required',
        v => (!isNaN(v)) || 'Card number must be only numbers',
        v => v.length > 12 || 'Card number must be at least 13 characters',
        v => v.length < 20 || 'Card number must be at most 19 characters',
        v => this.vllidateCard(v) || 'Please enter a valid card number'
      ],
      cvvRules: [
        v => !!v || 'CVV is required',
        v => (!isNaN(v)) || 'CVV must be only numbers',
        v => v.length > 2 || 'CVV must be at least 3 characters',
        v => v.length < 5 || 'CVV must be at most 4 characters'
      ],
      requiredRule: [
        v => !!v || 'Field is required'
      ]
    }
  },
  computed: {
    cardExpireDateText () {
      if (this.cardExpireDate) return this.formatDate(this.cardExpireDate)
      else return ''
    }
  },
  watch: {
    cardNum (val) {
      if (val) {
        const cards = cardType(val)
        if (cards.length > 0) {
          this.cardCode = cards[0].type
        }
      } else this.cardCode = ''
    }
  },
  methods: {
    vllidateCard (value) {
      const cards = cardType(value)
      if (cards.length > 0) return true
      else return false
    },
    formatDate (date) {
      if (!date) return null
      const [year, month] = date.split('-')
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      return `${months[month - 1]} ${year}`
    },
    changeCardNum () {
      this.$emit('card', this.cardNum, this.cardCode)
    },
    changeExpiry () {
      if (this.cardExpireDate) {
        this.$emit('expire', this.cardExpireDate)
      }
    },
    setCVV () {
      this.$emit('cvv', this.cvv)
    }
  }
}
</script>

<style>
  .remove-controls input[type='number'] {
    -moz-appearance:textfield;
  }
  .remove-controls input::-webkit-outer-spin-button,
  .remove-controls input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
</style>
