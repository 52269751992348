const endPoint = process.env.NODE_ENV === 'development' ? 'https://dev.atnbookingengine.com/api' : 'https://www.atnbookingengine.com/api'
const endPointV2 = process.env.NODE_ENV === 'development' ? 'https://dev.atnbookingengine.com/v2/api' : 'https://www.atnbookingengine.com/v2/api'

// app currency
export const currencies = `${endPoint}/getCurrencies`

// flights
export const getAirlines = (term) => `${endPointV2}/flights/airlines/autocomplete?term=${term}`
export const getAirports = (term) => `${endPoint}/airport/autocomplete?term=${term}`
export const searchFlights = (term) => `${endPoint}/flights/search?${term}`
export const getFlight = (id, search, relatedFlightId) => {
  if (relatedFlightId) return `${endPoint}/flights?flight_id=${id}&search_id=${search}&related_flight_id=${relatedFlightId}`
  else return `${endPoint}/flights?flight_id=${id}&search_id=${search}`
}
export const createPNR = `${endPoint}/pnr/create`
export const showPNR = (pnr) => `${endPoint}/pnr/${pnr}`
export const bookFlightByMail = `${endPointV2}/flights/send-special-offer-request-mail`

// cars
export const carSearch = (term) => `${endPoint}/cars/search?${term}`
export const showCar = (term) => `${endPoint}/cars/show?${term}`
export const rentCar = `${endPoint}/cars/book`

// hotels
export const getCities = (term) => `${endPoint}/cities/autocomplete?term=${term}`
export const searchHotels = (term) => `${endPoint}/hotels/search?${term}`
export const getHotel = (sessionId, hotelIndex, hotelCode) => `${endPoint}/hotels/show?sessionId=${sessionId}&hotelIndex=${hotelIndex}&hotelCode=${hotelCode}`
export const hotelAvailability = (term) => `${endPoint}/hotels/roomsAvailabilityAndPricing?${term}`
export const bookHotel = `${endPoint}/hotels/book`

// headers
export function headersNoAuth () {
  return { Accept: 'application/json', 'Content-type': 'application/json' }
}
