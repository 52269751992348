<template>
    <v-col cols="12">
        <v-card
        v-for="hotel in hotels.Hotels"
        :key="hotel.HotelIndex"
        class="mb-5 d-none d-sm-block"
        >
            <div class="d-flex flex-no-wrap">
                <div class="image-div">
                    <span>
                        <img :alt="hotel.HotelName" class="image" :src="hotel.HotelPicture"/>
                    </span>
                </div>
                <div>
                    <v-card-title
                    class="headline"
                    >
                        {{hotel.HotelName}}
                        <v-rating dense :length="hotel.stars" color="warning" readonly x-small  :value="hotel.stars" full-icon="mdi-star"></v-rating>
                        <v-spacer></v-spacer>
                        <div v-if="hotel.TripAdviserUrl">
                            <v-avatar color="indigo" size="36">
                                <span class="white--text headline">{{hotel.TripAdviserRating}}</span>
                            </v-avatar>
                        </div>
                    </v-card-title>

                    <v-card-subtitle>
                        {{hotel.HotelAddress}}
                        <v-btn text color="blue" small @click="openMap(hotel.HotelIndex, hotel.HotelOnMap)">
                            Show on map
                        </v-btn>
                    </v-card-subtitle>

                    <v-card-text>
                        <v-row justify="space-between">
                            <v-col cols="8">
                                <p class="body-2">{{hotel.HotelDescription}}</p>
                            </v-col>
                            <div>
                                <v-subheader>{{getNights()}} Nights, {{hotels.roomGuests.Adults + hotels.roomGuests.Children}} Guests</v-subheader>
                                <p class="display-1 font-weight-bold primary--text">${{hotel.pricingInfo.totalPrice.toFixed(2)}}</p>
                            </div>
                        </v-row>
                    </v-card-text>
                </div>
            </div>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                tile
                color="secondary"
                @click="select(hotel.HotelIndex, hotel.HotelCode)"
                >
                    See availability
                    <v-icon small right color="white">mdi-chevron-right</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-dialog
        v-model="dialog1"
        max-width="80%"
        >
            <gmap-map
            :center="center"
            :zoom="16"
            style="width:100%;  height: 500px;"
            zoomControl
            class="mt-1"
            >
                <gmap-marker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                @click="center=m.position"
                :clickable="true"
                :draggable="true"
                ></gmap-marker>
            </gmap-map>
        </v-dialog>

        <v-card
        class="mx-auto my-5 d-block d-sm-none"
        v-for="hotel in hotels.Hotels"
        :key="hotel.HotelCode"
        >

            <span>
                <img height="200" width="100%" :alt="hotel.HotelName" :src="hotel.HotelPicture"/>
            </span>

            <v-card-title class="font-weight-bolder">{{hotel.HotelName}}</v-card-title>

            <v-card-text>
            <v-row
                align="center"
                class="mx-0"
            >
                <v-rating
                :value="hotel.stars"
                color="warning"
                readonly
                small
                :length="hotel.stars"
                ></v-rating>

                <div v-if="hotel.TripAdviserUrl">
                    <v-avatar color="indigo" size="36">
                        <span class="white--text headline">{{hotel.TripAdviserRating}}</span>
                    </v-avatar>
                </div>
            </v-row>

            <div class="my-4 subtitle-1">
                {{hotel.HotelAddress}}
                <v-btn text color="blue" small @click="openMap(hotel.HotelIndex, hotel.HotelOnMap)">
                    Show on map
                </v-btn>
            </div>

            <div>{{hotel.HotelDescription}}</div>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>

            <v-card-title class="font-weight-bold">${{hotel.pricingInfo.totalPrice.toFixed(2)}}</v-card-title>

            <v-card-text>
                {{getNights()}}Nights, {{hotels.roomGuests.Adults + hotels.roomGuests.Children}} Guests
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                tile
                color="secondary"
                @click="select(hotel.HotelIndex, hotel.HotelCode, $store.state.hotelSearch.guestsInfo.length)"
                >
                    See availability
                    <v-icon small right color="white">mdi-chevron-right</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</template>

<script>
export default {
  props: ['hotels', 'session'],
  data () {
    return {
    //   hotels: null,
      showPlaceholderImage: false,
      src: '',
      center: {
        lat: 0,
        lng: 0
      },
      markers: [],
      dialog: false,
      dialog1: false,
      now: null
    }
  },
  watch: {
    'this.$store.state.hotels' (newVal) {
      this.hotels = newVal
    },
    hotels () {
      this.now = Date.now()
    }
  },
  methods: {
    openMap (index, position) {
      this.dialog1 = !this.dialog1
      this.center.lat = parseFloat(position.Latitude)
      this.center.lng = parseFloat(position.Longitude)
      this.markers.push({ position: this.center })
    },
    getPosition (position) {
      this.center.lat = parseFloat(position.Latitude)
      this.center.lng = parseFloat(position.Longitude)
      this.markers.push({ position: this.center })
    },
    getNights () {
      const checkIn = new Date(this.$store.state.hotelSearch.checkInDate)
      const checkOut = new Date(this.$store.state.hotelSearch.checkOutDate)
      const DifferenceInTime = checkOut.getTime() - checkIn.getTime()
      return DifferenceInTime / (1000 * 3600 * 24)
    },
    select (index, code, rooms) {
      this.$store.state.hotelSessionId = this.session
      this.$store.state.hotelIndex = index
      this.$store.state.hotelCode = code
      this.$store.state.roomsNo = rooms
      this.$store.dispatch('setShowHotelResults', false)
      this.$store.dispatch('setShowOneHotel', true)
    }
  },
  created () {
    this.now = Date.now()
  }
}
</script>

<style>
    .image-div {
      padding: 10px;
      max-height: 100%;
    }
    .image-div span {
        height: 100%;
    }
    .image {
      height: 200px;
      width: 200px;
    }
</style>
