<template>
  <div>
  <!-- <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :close-on-click="true"
    bottom
    transition="scale-transition"
    max-width="auto"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
      name="text"
      solo
      v-model="passengerInfo"
      v-on="on"
      prepend-inner-icon="mdi-account-multiple"
      color="secondary"
      height="52"
      readonly
      class="mr-1 pt-1"
      ></v-text-field>
    </template> -->
     <v-text-field
      name="text"
      solo
      v-model="passengerInfo"
      prepend-inner-icon="mdi-account-multiple"
      color="secondary"
      height="52"
      readonly
      class="mr-1 pt-1"
      @focus="showCard = true"
      ></v-text-field>
    <v-card v-if="showCard" style="transform: translateY(-20px); z-index: 99;" min-width="300px">
      <v-card-text>
          <v-select
          :items="classes"
          v-model="passengers.classType"
          label="Class"
          item-text="text"
          item-value="value"
          color="secondary"
          prepend-icon="mdi-label-variant"
          ></v-select>

          <v-slider
            v-model="passengers.adults"
            color="secondary"
            track-color="grey"
            always-dirty
            label="adults"
            min="1"
            max="9"
            prepend-icon="mdi-account"
            :tick-labels="[1,2,3,4,5,6,7,8,9]"
            tick-size="1"
          >
          </v-slider>

          <v-slider
            v-model="passengers.children"
            color="secondary"
            track-color="grey"
            always-dirty
            label="Children"
            min="0"
            :max="children"
            prepend-icon="mdi-car-child-seat"
            :tick-labels="childrenLabels"
            tick-size="1"
            :disabled="childrenLabels.length === 1"
            >
          </v-slider>

          <v-slider
            v-model="passengers.infants"
            color="secondary"
            track-color="grey"
            label="infants"
            always-dirty
            min="0"
            :max="infants"
            prepend-icon="mdi-account-child"
            :tick-labels="infantsLabels"
            tick-size="1"
            >
          </v-slider>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="showCard = false" color="error">Cancel</v-btn>
              <v-btn color="success" text @click="save">Done</v-btn>
          </v-card-actions>
      </v-card-text>
    </v-card>
  <!-- </v-menu> -->
  </div>
</template>

<script>
export default {
  data: () => ({
    showCard: false,
    passengerInfo: '1 passenger, Economy',
    passengers: {
      adults: Number(1),
      children: 0,
      infants: 0,
      classType: 'Y'
    },
    classes: [
      { text: 'Economy', value: 'Y' },
      { text: 'Premium Economy', value: 'P' },
      { text: 'Business', value: 'C' },
      { text: 'First', value: 'F' }
    ]
  }),
  computed: {
    children () {
      const number = 9 - this.passengers.adults
      return Number(number)
    },
    childrenLabels () {
      const labels = []
      for (let i = 0; i <= this.children; i++) {
        labels.push(i)
      }
      return labels
    },
    infants () {
      const number = this.passengers.adults
      return Number(number)
    },
    infantsLabels () {
      const labels = []
      for (let i = 0; i <= this.passengers.adults; i++) {
        labels.push(i)
      }
      return labels
    }
  },
  watch: {
    'passengers.adults' (newVal, oldVal) {
      const limit = 9 - newVal
      if (oldVal > newVal) {
        if (this.infants > newVal) {
          this.infants -= 1
        }
      } else if (oldVal < newVal) {
        if (this.children > limit) {
          this.children -= 1
        }
      }
    }
  },
  methods: {
    save () {
      this.getLabel()
      this.$store.dispatch('setPassengersAdults', Number(this.passengers.adults))
      this.$store.dispatch('setPassengersChildren', Number(this.passengers.children))
      this.$store.dispatch('setPassengersInfants', Number(this.passengers.infants))
      this.$store.dispatch('setPassengersClass', this.passengers.classType)
      this.showCard = false
    },
    getLabel () {
      let flightClass
      switch (this.passengers.classType) {
        case 'Y':
          flightClass = 'Economy'
          break
        case 'C':
          flightClass = 'Business'
          break
        case 'F':
          flightClass = 'First'
          break
        case 'P':
          flightClass = 'Premium'
          break
        default:
          break
      }
      const passengerNumber = Number(this.passengers.adults) + Number(this.passengers.children) + Number(this.passengers.infants)
      this.passengerInfo = `${passengerNumber} passengers, ${flightClass}`
    }
  },
  created () {
    this.passengers = this.$store.state.passengers
    this.getLabel()
  }
}
</script>
