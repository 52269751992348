<template>
    <v-col cols="12">
        <v-card
        v-for="(car, i) in cars.cars"
        :key="i"
        class="mb-5"
        >
            <v-row justify="space-around">
                <v-col cols="4" class="pa-5">
                    <p :class="`${$vuetify.breakpoint.mdAndUp ? 'display-1' : 'body-1'} secondary--text font-weight-bold`">{{car.carInfo.carCategory}}<small class="body-1 primary--text">, {{car.carInfo.carType}}</small></p>
                    <img v-if="car.carInfo.images.length > 0 && car.carInfo.images.length > 0" :src="car.carInfo.images[0].Url" width="100%">
                </v-col>
                <v-col cols="12" sm="4" class="pa-5">
                    <img v-if="car.vendorLogo" :src="car.vendorLogo" alt="logo" width="100">
                    <span v-if="!car.vendorLogo" class="body-1 primary--text font-weight-bold">{{car.vendorName}}</span>
                    <br>
                    <v-chip
                    class="ma-1"
                    color="secondary"
                    outlined
                    v-if="car.carInfo.carTrans"
                    >
                    <v-icon left>mdi-car-cog</v-icon>
                    {{car.carInfo.carTrans}}
                    </v-chip>
                    <v-chip
                    class="ma-1"
                    color="secondary"
                    outlined
                    v-if="car.carInfo.seats && car.carInfo.seats > 0"
                    >
                    <v-icon left>mdi-car-seat</v-icon>
                    {{car.carInfo.seats}} Seats
                    </v-chip>
                    <v-chip
                    class="ma-1"
                    color="secondary"
                    outlined
                    v-if="car.carInfo.carFuel"
                    >
                    <v-icon left>mdi-fuel</v-icon>
                    {{car.carInfo.carFuel}}
                    </v-chip>
                    <v-chip
                    class="ma-1"
                    color="secondary"
                    outlined
                    v-if="car.carInfo.bags && car.carInfo.bags.length > 0"
                    >
                    <v-icon left>mdi-bag-checked</v-icon>
                    <span v-for="(item, n) in car.carInfo.bags" :key="n"> . {{ item.Quantity}} {{item.Size}} bag/s </span>
                    </v-chip>
                </v-col>
                <v-col cols="12" sm="4" class="text-right pa-5">
                    <p class="body-2 primary--text font-weight-bold mr-2" v-for="(fare, l) in car.fareInfo" :key="l">
                      <span v-if="fare.ChargeType === 'totalPrice'">
                        <strong class="body-1 primary--text font-weight-bold">${{fare.Amount}}</strong> {{fare.ChargeType}}, {{fare.MileageAllowance === 'UNL' ? 'Unlimited' : fare.MileageAllowance}} miles, <br>{{fare.MileageAllowance !== 'UNL' ? `Extra miles $${fare.ExtraMileageCharge}/mile` : ''}}
                      </span>
                    </p>
                </v-col>
            </v-row>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn tile color="secondary" @click="select(i, cars.search_id)">
                    Select this car
                    <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</template>

<script>
export default {
  props: ['cars'],
  data () {
    return {
      now: null
    }
  },
  watch: {
    'this.$store.state.cars' (newVal) {
      this.cars = newVal
    },
    flights () {
      this.now = Date.now()
    }
  },
  methods: {
    select (index, session) {
      this.$store.state.carSearchId = session
      this.$store.state.carId = index
      this.$store.dispatch('setShowCarBook', true)
    }
  },
  created () {
    this.now = Date.now()
  }
}
</script>
