<template>
  <div class="picker-range">
    <v-icon :color="iconColor">mdi-calendar</v-icon>
    <date-range-picker
    class="range-input"
    ref="picker"
    opens="center"
    :single-date-picker="(type === 'dep-round' || type === 'hotel') ? false : true"
    :locale-data="{
      direction: 'ltr',
      format: 'mm/dd/yyyy',
      separator: ' - ',
      applyLabel: 'Apply',
      cancelLabel: 'Cancel',
      weekLabel: 'W',
      customRangeLabel: 'Custom Range',
      daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      firstDay: 1
    }"
    :minDate="tomorrow"
    :autoApply="true"
    :ranges="false"
    v-model="dateRange"
    @toggle="coloringIcon"
    @select="setDates"
    >
      <template v-slot:input="" style="min-width: 350px;">
        <span class="body-2 text-no-wrap">{{ dateRange.startDate ? new Date(dateRange.startDate).toString().substring(4, 15) : placeholder() }} {{ dateRange.endDate && (type === 'dep-round' || type === 'hotel') ? `- ${new Date(dateRange.endDate).toString().substring(4, 15)}` : '' }}</span>
      </template>
    </date-range-picker>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  props: ['type'],
  components: { DateRangePicker },
  data () {
    return {
      dateRange: {},
      menu: false,
      today: new Date().toISOString().substr(0, 10),
      minDate: null,
      disable: false,
      iconColor: '#757575'
    }
  },
  computed: {
    tomorrow () {
      const tomorrow = new Date(this.today)
      tomorrow.setDate(tomorrow.getDate())
      return tomorrow.toISOString().slice(0, 10)
    }
  },
  methods: {
    coloringIcon (toggle) {
      toggle ? this.iconColor = 'secondary' : this.iconColor = '#757575'
    },
    placeholder () {
      let value
      switch (this.type) {
        case 'dep-round':
          value = 'Trip Date'
          break
        case 'hotel':
          value = 'CheckIn - CheckOut'
          break
        default:
          value = this.type
          break
      }
      return value
    },
    setDates (dates) {
      switch (this.type) {
        case 'dep-round':
          this.$store.dispatch('setDates', { data: dates.startDate.toISOString().substr(0, 10), type: 'departure1' })
          this.$store.dispatch('setDates', { data: dates.endDate.toISOString().substr(0, 10), type: 'arrival' })
          break
        case 'Departure Date':
          this.$store.dispatch('setDates', { data: dates.startDate.toISOString().substr(0, 10), type: 'departure1' })
          break
        case 'Second Departure Date':
          this.$store.dispatch('setDates', { data: dates.startDate.toISOString().substr(0, 10), type: 'departure2' })
          break
        case 'Third Departure Date':
          this.$store.dispatch('setDates', { data: dates.startDate.toISOString().substr(0, 10), type: 'departure3' })
          break
        case 'Pickup Date':
          this.$store.dispatch('setDates', { data: dates.startDate.toISOString().substr(0, 10), type: 'pickup' })
          break
        case 'Drop Date':
          this.$store.dispatch('setDates', { data: dates.startDate.toISOString().substr(0, 10), type: 'drop' })
          break
        case 'hotel':
          this.$store.dispatch('setHotelCheckInDate', dates.startDate.toISOString().substr(0, 10))
          this.$store.dispatch('setHotelCheckOutDate', dates.endDate.toISOString().substr(0, 10))
          break
        default:
          break
      }
    },
    afterTomorrow (days) {
      const today = new Date(this.today)
      const afterTomorrow = today.setDate(today.getDate() + days)
      return afterTomorrow
    }
  },
  created () {
    switch (this.type) {
      case 'dep-round':
        this.dateRange.startDate = this.$store.state.flightDates.departureDates[0] ? new Date(this.$store.state.flightDates.departureDates[0]) : this.afterTomorrow(1)
        this.dateRange.endDate = this.$store.state.flightDates.arrivalDate ? new Date(this.$store.state.flightDates.arrivalDate) : this.afterTomorrow(2)
        this.$store.dispatch('setDates', { data: new Date(this.dateRange.startDate).toISOString().substr(0, 10), type: 'departure1' })
        this.$store.dispatch('setDates', { data: new Date(this.dateRange.endDate).toISOString().substr(0, 10), type: 'arrival' })
        break
      case 'Departure Date':
        this.dateRange.startDate = this.$store.state.flightDates.departureDates[0] ? new Date(this.$store.state.flightDates.departureDates[0]) : this.afterTomorrow(1)
        this.dateRange.endDate = this.$store.state.flightDates.arrivalDate ? new Date(this.$store.state.flightDates.arrivalDate) : this.afterTomorrow(2)
        this.$store.dispatch('setDates', { data: new Date(this.dateRange.startDate).toISOString().substr(0, 10), type: 'departure1' })
        this.$store.dispatch('setDates', { data: new Date(this.dateRange.endDate).toISOString().substr(0, 10), type: 'arrival' })
        break
      case 'Second Departure Date':
        this.dateRange.startDate = this.$store.state.flightDates.departureDates[1] ? new Date(this.$store.state.flightDates.departureDates[1]) : this.afterTomorrow(2)
        this.$store.dispatch('setDates', { data: new Date(this.dateRange.startDate).toISOString().substr(0, 10), type: 'departure2' })
        break
      case 'Third Departure Date':
        this.dateRange.startDate = this.$store.state.flightDates.departureDates[2] ? new Date(this.$store.state.flightDates.departureDates[2]) : this.afterTomorrow(3)
        this.$store.dispatch('setDates', { data: new Date(this.dateRange.startDate).toISOString().substr(0, 10), type: 'departure3' })
        break
      case 'Pickup Date':
        this.dateRange.startDate = this.$store.state.carInfo.pickup ? new Date(this.$store.state.carInfo.pickup) : this.afterTomorrow(1)
        this.$store.dispatch('setDates', { data: new Date(this.dateRange.startDate).toISOString().substr(0, 10), type: 'pickup' })
        break
      case 'Drop Date':
        this.dateRange.startDate = this.$store.state.carInfo.drop ? new Date(this.$store.state.carInfo.drop) : this.afterTomorrow(2)
        this.$store.dispatch('setDates', { data: new Date(this.dateRange.startDate).toISOString().substr(0, 10), type: 'drop' })
        break
      case 'hotel':
        this.dateRange.startDate = this.$store.state.hotelSearch.checkInDate ? new Date(this.$store.state.hotelSearch.checkInDate) : this.afterTomorrow(1)
        this.dateRange.endDate = this.$store.state.hotelSearch.checkOutDate ? new Date(this.$store.state.hotelSearch.checkOutDate) : this.afterTomorrow(2)
        this.$store.dispatch('setHotelCheckInDate', new Date(this.dateRange.startDate).toISOString().substr(0, 10))
        this.$store.dispatch('setHotelCheckOutDate', new Date(this.dateRange.endDate).toISOString().substr(0, 10))
        break
      default:
        break
    }
  }
}
</script>

<style>
  .picker-range {
    width: 99%;
    height: 52px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0px 2px 2px grey;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 10px 15px;
    display: flex;
  }
  .range-input {
    width: 90%;
  }
  .reportrange-text[data-v-267f4ee2] {
    border: none !important;
  }
  .daterangepicker.openscenter[data-v-267f4ee2] {
    transform: translate(-50%, -20%) !important;
  }
</style>
