<template>
    <div>
        <v-snackbar v-model="snackbar" top :timeout="5000" :color="color">
          {{text}}
          <v-btn icon @click="snackbar = false"><v-icon color="white">mdi-close</v-icon></v-btn>
        </v-snackbar>
        <v-snackbar
        color="warning"
        top
        multiLine
        v-model="warningBar"
        :timeout="3000"
        >
          Sorry, we couldn't find any flights that match your search
          <v-btn
            dark
            icon
            @click="warningBar = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-snackbar>
        <v-container fluid>
            <v-row>
                <v-col cols="12" class="d-block d-md-none">
                    <v-expansion-panels hover>
                        <v-expansion-panel>
                            <v-expansion-panel-header expand-icon="mdi-magnify">Search Flights</v-expansion-panel-header>
                            <v-expansion-panel-content color="#c7c7c7">
                              <search @addFlights="getAdditionalFlights" />
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel v-if="flightsStore.length > 1">
                            <v-expansion-panel-header expand-icon="mdi-filter-menu">Filter Results</v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <filters :mailonlyFaresExist="mailonlyFaresExist" :alternateFlightsExist="alternateFlightsExist" v-if="showMatrix" @changed="filter"/>
                              <p v-else class="text-center">Preparing Filters ...</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
                <v-col cols="3" xl="2" class="d-none d-md-flex" v-if="flightsStore.length > 1">
                    <v-card class="pa-1" width="100%">
                      <filters :mailonlyFaresExist="mailonlyFaresExist" :alternateFlightsExist="alternateFlightsExist" v-if="showMatrix" @changed="filter"/>
                      <p v-else class="text-center">Preparing Filters ...</p>
                    </v-card>
                </v-col>
                <v-col cols="12" :md="flightsStore.length === 1 ? 12 : 9" :xl="flightsStore.length === 1 ? 12 : 10">
                    <matrix @choose="filter" :netMatrix="netMatrix" :alterMatrix="alterMatrix" v-if="flightsStore.length > 1 && showMatrix"/>
                    <div style="width: 100%; text-align: center;">
                      <v-progress-circular
                      class="ma-5"
                      v-if="alternateLoad && alternateFlights.length === 0"
                      :size="70"
                      :width="7"
                      color="purple"
                      indeterminate
                      ></v-progress-circular>
                      <!-- <flightCard v-if="!alternateLoad && alternateFlights.length > 0" :flights="alternateFlights" type="alternate" /> -->
                    </div>
                    <v-divider v-if="!alternateLoad && alternateFlights.length > 0" class="my-5"></v-divider>
                    <flightCard :flights="flights" />
                </v-col>
                <p v-if="!alternateLoad && flights.length === 0 && alternateFlights.length === 0" class="body-1 font-weight-bold mx-auto primary--text text-center">Sorry, No flights found .</p>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import search from '../components/flightSearch.vue'
import flightCard from '../components/flightCard.vue'
import filters from '../components/filters.vue'
import matrix from '../components/matrix.vue'
import { searchFlights, headersNoAuth } from '../links'

export default {
  title: 'Fare33-Flight results',
  components: {
    search,
    flightCard,
    filters,
    matrix
  },
  data () {
    return {
      flights: [],
      snackbar: false,
      color: '',
      text: '',
      alternateLoad: true,
      alternateFlights: [],
      netMatrix: [],
      alterMatrix: [],
      showMatrix: false,
      warningBar: false,
      mailonlyFaresExist: false,
      alternateFlightsExist: false
    }
  },
  computed: {
    flightsStore () {
      return this.$store.state.flightResults.flights
    },
    min () {
      return this.$store.state.flightResults.minPrice
    },
    max () {
      return this.$store.state.flightResults.maxPrice
    }
  },
  watch: {
    flightsStore (newVal) {
      this.flights = newVal
    }
  },
  methods: {
    airLabel (name, code, price) {
      const label = name + code + price
      return label
    },
    filter (flights) {
      if (flights.length > 0) {
        this.flights = flights
      } else {
        this.flights = flights
      }
    },
    getAdditionalFlights () {
      this.alternateLoad = true
      const results = this.$store.state.flightResults
      const sessionId = this.$store.state.flightResults.search_id
      this.$http.get(searchFlights(localStorage.getItem('alternateSearchTerm') + `&search_id=${sessionId}`), { headers: headersNoAuth() }).then(response => {
        this.searchBtnLoad = false
        this.alternateDatesFound = false
        this.alterMatrix = []
        if (response.body.status === true) {
          if (this.flights.length > 0) {
            this.alternateLoad = false
            const alternateFlights = response.body.data.flights.reduce((acc, current) => {
              const x = acc.find(item => {
                return (item.pricingInfo.TotalFare === current.pricingInfo.TotalFare && item.flightSegments[0].DepartureDate === current.flightSegments[0].DepartureDate && item.flightSegments[0].MarketingAirlineName === current.flightSegments[0].MarketingAirlineName)
              })
              if (!x) {
                return acc.concat([current])
              } else {
                return acc
              }
            }, [])
            const cheapestFlight = this.flights.sort((a, b) => {
              let x
              let y
              if (a.pricingInfo.newFare > 0) x = a.pricingInfo.newFare
              else x = a.pricingInfo.TotalFare
              if (b.pricingInfo.newFare > 0) y = b.pricingInfo.newFare
              else y = b.pricingInfo.TotalFare
              return x - y
            })[0]
            const cheapestAltFlight = alternateFlights.sort((a, b) => {
              let x
              let y
              if (a.pricingInfo.newFare > 0) x = a.pricingInfo.newFare
              else x = a.pricingInfo.TotalFare
              if (b.pricingInfo.newFare > 0) y = b.pricingInfo.newFare
              else y = b.pricingInfo.TotalFare
              return x - y
            })[0]
            if (cheapestAltFlight.pricingInfo.TotalFare < cheapestFlight.pricingInfo.TotalFare) {
              this.alterMatrix = response.body.data.FilterMatrix
              if (response.body.data.minPrice < results.minPrice) this.$store.dispatch('setFlightsMinPrice', response.body.data.minPrice)
              if (response.body.data.maxPrice > results.maxPrice) this.$store.dispatch('setFlightsMaxPrice', response.body.data.maxPrice)
              if (response.body.data.minStopsDuration < results.minStopsDuration) this.$store.dispatch('setFlightsMinStopsDuration', response.body.data.minStopsDuration)
              if (response.body.data.maxStopsDuration > results.maxStopsDuration) this.$store.dispatch('setFlightsMaxStopsDuration', response.body.data.maxStopsDuration)
              if (response.body.data.airlines.length > 0) this.$store.dispatch('setAltAirlines', response.body.data.airlines)
              alternateFlights.forEach((item, i) => {
                this.flights.push(item)
              })
              this.alternateFlightsExist = true
            } else {
              this.alternateLoad = false
            }
          } else {
            this.$store.dispatch('setFlightResults', response.body.data)
            this.flights = response.body.data.flights.reduce((acc, current) => {
              const x = acc.find(item => {
                return (item.pricingInfo.TotalFare === current.pricingInfo.TotalFare && item.flightSegments[0].DepartureDate === current.flightSegments[0].DepartureDate && item.flightSegments[0].MarketingAirlineName === current.flightSegments[0].MarketingAirlineName)
              })
              if (!x) {
                return acc.concat([current])
              } else {
                return acc
              }
            }, [])
            this.$store.dispatch('setFlightsMinPrice', response.body.data.minPrice)
            this.$store.dispatch('setFlightsMaxPrice', response.body.data.maxPrice)
            this.$store.dispatch('setFlightsMinStopsDuration', response.body.data.minStopsDuration)
            this.$store.dispatch('setFlightsMaxStopsDuration', response.body.data.maxStopsDuration)
            this.$store.dispatch('setAltAirlines', response.body.data.airlines)
            this.alternateLoad = false
          }
        } else {
          this.alternateLoad = false
        }
        this.flights = this.flights.reduce((acc, current) => {
          const x = acc.find(item => {
            if (item.mailOnlyFare) this.mailonlyFaresExist = true
            return (item.mailOnlyFare && current.mailOnlyFare && item.pricingInfo.TotalFare === current.pricingInfo.TotalFare)
          })
          if (!x) {
            return acc.concat([current])
          } else {
            return acc
          }
        }, [])
        this.flights.sort((a, b) => {
          let x
          let y
          if (a.pricingInfo.newFare > 0) x = a.pricingInfo.newFare
          else x = a.pricingInfo.TotalFare
          if (b.pricingInfo.newFare > 0) y = b.pricingInfo.newFare
          else y = b.pricingInfo.TotalFare
          return x - y
        })
        this.$store.dispatch('updateFlights', this.flights)
        this.showMatrix = true
        this.loaded = false
      }, () => {
        this.alternateLoad = false
        this.searchBtnLoad = false
        this.loaded = false
      })
    }

    // getAdditionalFlights () {
    //   this.alternateLoad = true
    //   const results = this.$store.state.flightResults
    //   const sessionId = this.$store.state.flightResults.search_id
    //   this.$http.get(searchFlights(localStorage.getItem('alternateSearchTerm') + `&search_id=${sessionId}`), { headers: headersNoAuth() }).then(response => {
    //     this.searchBtnLoad = false
    //     this.alternateDatesFound = false
    //     this.alterMatrix = []
    //     if (response.body.status === true) {
    //       if (this.flights.length > 0) {
    //         this.alternateLoad = false
    //         const alternateFlights = response.body.data.flights.reduce((acc, current) => {
    //           const x = acc.find(item => {
    //             return (item.pricingInfo.TotalFare === current.pricingInfo.TotalFare && item.flightSegments[0].DepartureDate === current.flightSegments[0].DepartureDate && item.flightSegments[0].MarketingAirlineName === current.flightSegments[0].MarketingAirlineName)
    //           })
    //           if (!x) {
    //             return acc.concat([current])
    //           } else {
    //             return acc
    //           }
    //         }, [])
    //         const cheapestFlight = this.flights.sort((a, b) => {
    //           let x
    //           let y
    //           if (a.pricingInfo.newFare > 0) x = a.pricingInfo.newFare
    //           else x = a.pricingInfo.TotalFare
    //           if (b.pricingInfo.newFare > 0) y = b.pricingInfo.newFare
    //           else y = b.pricingInfo.TotalFare
    //           return x - y
    //         })[0]
    //         const cheapestAltFlight = alternateFlights.sort((a, b) => {
    //           let x
    //           let y
    //           if (a.pricingInfo.newFare > 0) x = a.pricingInfo.newFare
    //           else x = a.pricingInfo.TotalFare
    //           if (b.pricingInfo.newFare > 0) y = b.pricingInfo.newFare
    //           else y = b.pricingInfo.TotalFare
    //           return x - y
    //         })[0]
    //         if (cheapestAltFlight.pricingInfo.TotalFare < cheapestFlight.pricingInfo.TotalFare) {
    //           this.alterMatrix = response.body.data.FilterMatrix
    //           if (response.body.data.minPrice < results.minPrice) this.$store.dispatch('setFlightsMinPrice', response.body.data.minPrice)
    //           if (response.body.data.maxPrice > results.maxPrice) this.$store.dispatch('setFlightsMaxPrice', response.body.data.maxPrice)
    //           // if (response.body.data.minStopsDuration < results.minStopsDuration) this.$store.dispatch('setFlightsMinStopsDuration', response.body.data.minStopsDuration)
    //           // if (response.body.data.maxStopsDuration > results.maxStopsDuration) this.$store.dispatch('setFlightsMaxStopsDuration', response.body.data.maxStopsDuration)
    //           // if (response.body.data.airlines.length > 0) this.$store.dispatch('setAltAirlines', response.body.data.airlines)
    //           alternateFlights.forEach((item, i) => {
    //             this.flights.push(item)
    //           })
    //           this.alternateFlightsExist = true
    //         } else {
    //           this.alternateLoad = false
    //         }
    //       } else {
    //         this.$store.dispatch('setFlightResults', response.body.data)
    //         this.flights = response.body.data.flights.reduce((acc, current) => {
    //           const x = acc.find(item => {
    //             return (item.pricingInfo.TotalFare === current.pricingInfo.TotalFare && item.flightSegments[0].DepartureDate === current.flightSegments[0].DepartureDate && item.flightSegments[0].MarketingAirlineName === current.flightSegments[0].MarketingAirlineName)
    //           })
    //           if (!x) {
    //             return acc.concat([current])
    //           } else {
    //             return acc
    //           }
    //         }, [])
    //         this.$store.dispatch('setFlightsMinPrice', response.body.data.minPrice)
    //         this.$store.dispatch('setFlightsMaxPrice', response.body.data.maxPrice)
    //         // this.$store.dispatch('setFlightsMinStopsDuration', response.body.data.minStopsDuration)
    //         // this.$store.dispatch('setFlightsMaxStopsDuration', response.body.data.maxStopsDuration)
    //         // this.$store.dispatch('setAltAirlines', response.body.data.airlines)
    //         this.alternateLoad = false
    //       }
    //     } else {
    //       this.alternateLoad = false
    //     }
    //     this.flights = this.flights.reduce((acc, current) => {
    //       const x = acc.find(item => {
    //         if (item.mailOnlyFare) this.mailonlyFaresExist = true
    //         return (item.mailOnlyFare && current.mailOnlyFare && item.pricingInfo.TotalFare === current.pricingInfo.TotalFare)
    //       })
    //       if (!x) {
    //         return acc.concat([current])
    //       } else {
    //         return acc
    //       }
    //     }, [])
    //     this.flights.sort((a, b) => {
    //       let x
    //       let y
    //       if (a.pricingInfo.newFare > 0) x = a.pricingInfo.newFare
    //       else x = a.pricingInfo.TotalFare
    //       if (b.pricingInfo.newFare > 0) y = b.pricingInfo.newFare
    //       else y = b.pricingInfo.TotalFare
    //       return x - y
    //     })
    //     this.$store.dispatch('updateFlights', this.flights)
    //     this.showMatrix = true
    //     this.loaded = false
    //   }, () => {
    //     this.alternateLoad = false
    //     this.searchBtnLoad = false
    //     this.loaded = false
    //   })
    // }
  },
  created () {
    this.$store.state.currentSearch = 'flights'
    const newArr = []
    this.$store.state.flightResults.flights.forEach(flight => {
      if (flight.alternate === undefined && flight.netFlight === undefined) {
        newArr.push(flight)
      }
    })
    this.flights = newArr
    this.getAdditionalFlights()
  }
}
</script>
