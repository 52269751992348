<template>
    <div>
        <v-container class="bg" fluid>
            <v-row
            align="center"
            justify="center"
            class="inner-cont"
            no-gutters
            >
                <v-col cols="12">
                    <hotelSearch @startLoad="loading = true" @endLoad="loading = false" />
                </v-col>
            </v-row>
        </v-container>
        <v-row v-if="loading" class="progress-row" justify="center" align="center">
            <v-progress-circular
            :size="70"
            :width="7"
            color="orange"
            indeterminate
            ></v-progress-circular>
        </v-row>
    </div>
</template>

<script>
import hotelSearch from '../components/hotelSearch.vue'
export default {
  title: '-Hotels search',
  components: {
    hotelSearch
  },
  data () {
    return {
      loading: false
    }
  }
}
</script>

<style>
    .progress-row {
        height: 40vh;
    }
</style>
