<template>
    <div style="background-color: white;">
        <v-progress-linear
            indeterminate
            color="secondary"
            height="10"
            top
            :active="loaded"
        ></v-progress-linear>
        <v-container>
            <v-snackbar top v-model="snackbar" :color="color" :timeout="5000">
                {{text}}
                <v-btn color="white" icon @click="snackbar = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-snackbar>
            <v-row v-if="loaded === false && dataReady === true">
                <v-col cols="12" sm="4" md="4" lg="5" class="pa-0">
                    <p class="display-1 secondary--text font-weight-bold mt-5">{{car.carInfo.carCategory}}, <span class="body-1 primary--text font-weight-bold">{{car.carInfo.carType}}</span></p>
                    <img v-if="car.carInfo.images && car.carInfo.images.length > 0" :src="car.carInfo.images[0].Url" width="70%">
                    <br>
                    <img v-if="car.vendorLogo" :src="car.vendorLogo" alt="logo" width="100">
                    <span v-if="!car.vendorLogo" class="body-1 primary--text font-weight-bold">{{car.vendorName}}</span>
                    <br>
                    <v-chip
                    class="ma-1"
                    color="secondary"
                    outlined
                    v-if="car.carInfo.carTrans"
                    >
                    <v-icon left>mdi-car-cog</v-icon>
                    {{car.carInfo.carTrans}}
                    </v-chip>
                    <v-chip
                    class="ma-1"
                    color="secondary"
                    outlined
                    v-if="car.carInfo.seats && car.carInfo.seats > 0"
                    >
                    <v-icon left>mdi-car-seat</v-icon>
                    {{car.carInfo.seats}} Seats
                    </v-chip>
                    <v-chip
                    class="ma-1"
                    color="secondary"
                    outlined
                    v-if="car.carInfo.carFuel"
                    >
                    <v-icon left>mdi-fuel</v-icon>
                    {{car.carInfo.carFuel}}
                    </v-chip>
                    <v-chip
                    class="ma-1"
                    color="secondary"
                    outlined
                    v-if="car.carInfo.bags && car.carInfo.bags.length > 0"
                    >
                    <v-icon left>mdi-bag-checked</v-icon>
                    <span v-for="(item, n) in car.carInfo.bags" :key="n"> . {{ item.Quantity}} {{item.Size}} bag/s </span>
                    </v-chip>
                    <br>
                    <v-row>
                        <v-col>
                            <p class="caption primary--text">Pickup from: {{car.pickUpLocation}}</p>
                            <p class="caption primary--text">Drop in: {{car.returnLocation}}</p>
                            <div v-for="(fare, i) in car.fareInfo" :key="i">
                                <strong v-if="fare.ChargeType === 'totalPrice' && paymentType !== 'points'" class="display-1 primary--text font-weight-bold">${{fare.Amount}} Total</strong>
                                <strong v-if="fare.ChargeType === 'totalPrice' && paymentType === 'points'" class="display-1 primary--text font-weight-bold">$0</strong>
                            </div>
                        </v-col>
                        <v-col>
                            <div v-for="(fare, i) in car.fareInfo" :key="i">
                                <span class="caption-1 primary--text font-weight-bold">${{fare.Amount}}</span> {{fare.ChargeType}}, {{fare.MileageAllowance === 'UNL' ? 'Unlimited' : fare.MileageAllowance}} miles, <br>{{fare.MileageAllowance !== 'UNL' ? `Extra miles $${fare.ExtraMileageCharge}/mile` : ''}}
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="8" md="8" lg="7" class="form">
                    <v-form v-model="valid" ref="form" class="mt-5">
                        <v-row>
                            <v-col cols="12" md="6" class="pt-0">
                                <!-- <v-text-field
                                    outlined
                                    v-model="phone"
                                    :rules="[v => !!v || 'Phone is required', v => (!isNaN(v)) || 'Phone must be only numbers']"
                                    class="remove-controls"
                                    label="Phone"
                                    type="number"
                                    required
                                    autocomplete="false"
                                    color="blue"
                                ></v-text-field> -->
                                <phoneInput @update="assignPhone" />
                            </v-col>
                            <v-col cols="12" md="6" class="pt-0">
                                <v-text-field
                                    outlined
                                    v-model="email"
                                    :rules="[v => !!v || 'E-mail is required', v => /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(v) || 'E-mail must be valid']"
                                    label="E-mail"
                                    required
                                    color="blue"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2" class="py-0">
                                <v-select
                                v-model="title"
                                :items="['Mr', 'Mrs', 'Ms', 'Miss']"
                                label="Title"
                                outlined
                                required
                                :rules="[v => !!v || 'Item is required']"
                                class="pa-0"
                                height="56px"
                                color="blue"
                                >
                                    <template v-slot:selection="{ item }">
                                        <span class="primary--text caption">{{item}}</span>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="5" class="py-0">
                                <v-text-field
                                    outlined
                                    v-model="fName"
                                    :rules="[v => (!!v && v.length > 1) || 'Item is required at least 2 characters', v => /^[A-Za-z][A-Za-z]*$/.test(v) || 'Name Must be letters only with no spaces']"
                                    label="First name"
                                    required
                                    color="blue"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="5" class="py-0">
                                <v-text-field
                                    outlined
                                    v-model="lName"
                                    :rules="[v => (!!v && v.length > 1) || 'Item is required at least 2 characters', v => /^[A-Za-z][A-Za-z]*$/.test(v) || 'Name Must be letters only with no spaces']"
                                    label="Last name"
                                    required
                                    color="blue"
                                ></v-text-field>
                            </v-col>
                            <v-divider></v-divider>
                            <v-col cols="4" class="py-0" v-if="paymentType === 'credit'">
                                <v-text-field
                                    outlined
                                    v-model="zip"
                                    :rules="[v => !!v || 'Item is required']"
                                    label="ZipCode"
                                    required
                                    color="blue"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="8" class="py-0" v-if="paymentType === 'credit'">
                                <v-text-field
                                    outlined
                                    v-model="address"
                                    :rules="[v => !!v || 'Item is required']"
                                    label="Address"
                                    required
                                    color="blue"
                                ></v-text-field>
                            </v-col>
                            <v-divider></v-divider>
                            <v-col cols="12" class="py-0" v-if="paymentType === 'credit'">
                                <v-text-field
                                    outlined
                                    v-model="cardHolder"
                                    :rules="[v => (!!v && v.length > 1) || 'Item is required at least 2 characters', v => /^[_A-z]*((-|\s)*[_A-z])*$/.test(v) || 'Name Must be letters only with no spaces']"
                                    label="Card Holder Name"
                                    required
                                    color="blue"
                                ></v-text-field>
                            </v-col>
                            <credit v-if="paymentType === 'credit'" class="px-4" @card="setCardData" @expire="setCardExpiry" @cvv="setCVV" />
                            <v-col cols="12" class="py-0">
                                <v-btn color="secondary" tile block :disabled="!valid" @click="rent" :loading="loading">Rent this car</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
            </v-row>
            <p v-if="loaded === false && dataReady === false" class="body-1 font-weight-bold primary--text mt-5">This car isn't available rightnow</p>
            <div class="text-center">
                <v-dialog
                v-model="dialog"
                persistent
                width="500"
                >
                    <v-card>
                        <v-card-title
                        class="headline secondary white--text"
                        >
                        Rented Successfully
                        </v-card-title>

                        <v-card-text>
                            <p class="body-1 primary--text font-weight-bold">Car rented successfully</p>
                            <p class="body-1 primary--text font-weight-bold" v-if="responseData">Your Book Reference: {{responseData.booking_reference}}</p>
                            <p class="body-1 primary--text font-weight-bold" v-if="responseData">Price: ${{responseData.price}}</p>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="secondary"
                            tile
                            @click="this.$store.dispatch('setShowCarBook', false), this.$store.dispatch('setShowCarResults', false)"
                        >
                            Finish
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </v-container>
    </div>
</template>

<script>
import { headersNoAuth, showCar, rentCar } from '../links'
import phoneInput from '../components/mobile.vue'
import credit from '../components/credit'

export default {
  components: {
    phoneInput,
    credit
  },
  data () {
    return {
      car: {},
      totalPrice: 0,
      phone: '',
      email: '',
      title: '',
      fName: '',
      lName: '',
      zip: '',
      paymentType: 'credit',
      address: '',
      creditNum: '',
      creditEx: '',
      creditType: '',
      cardHolder: '',
      loaded: true,
      dataReady: true,
      cards: [
        { label: 'Visa', code: 'VI' },
        { label: 'Master Card', code: 'CA' },
        { label: 'American Express', code: 'AX' },
        { label: 'Discover', code: 'DS' },
        { label: 'Diners Club', code: 'DN' }
      ],
      cardRules: [
        v => !!v || 'Card number is required',
        v => (!isNaN(v)) || 'Card number must be only numbers',
        v => v.length === 16 || 'Card number must be 16 characters'
      ],
      responseData: null,
      menu: false,
      valid: true,
      loading: false,
      dialog: false,
      color: '',
      text: '',
      snackbar: false,
      carsTimeout: null,
      carsOverlay: false
    }
  },
  computed: {
    creditExText () {
      if (this.creditEx) return this.formatDate(this.creditEx)
      else return ''
    }
  },
  methods: {
    assignPhone (phone) {
      this.phone = phone
    },
    setCardData (number, type) {
      this.creditNum = number
      switch (type) {
        case 'visa':
          this.creditType = 'VI'
          break
        case 'mastercard':
          this.creditType = 'CA'
          break
        case 'american-express':
          this.creditType = 'AX'
          break
        case 'discover':
          this.creditType = 'DS'
          break
        case 'diners-club':
          this.creditType = 'DN'
          break
        default:
          this.creditType = type
          break
      }
    },
    setCardExpiry (date) {
      this.creditEx = date
    },
    setCVV (cvv) {
      this.cvv = cvv
    },
    formatDate (date) {
      if (!date) return null
      const [year, month] = date.split('-')
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const newDate = `${months[month - 1]} ${year}`
      return newDate
    },
    rent () {
      this.loading = true
      this.$refs.form.validate()
      if (this.valid) {
        if (this.phone.valid) {
          const body = {
            contact_phone: this.phone.nationalNumber,
            countryIsoCode: this.phone.countryCode,
            contact_email: this.email,
            driverTitle: this.title,
            driverFirstName: this.fName,
            driverLastName: this.lName,
            zipCode: this.zip,
            address: this.address,
            creditCardExpireDate: this.creditEx,
            creditCardNumber: this.creditNum,
            creditCardType: this.creditType,
            cardHolderName: this.cardHolder,
            pickupDate: this.$store.state.carInfo.pickup,
            pickupTime: this.$store.state.carInfo.pickupTime,
            returnDate: this.$store.state.carInfo.drop,
            returnTime: this.$store.state.carInfo.dropTime,
            search_id: this.$store.state.carSearchId,
            car_id: this.$store.state.carId
          }
          this.$http.post(rentCar, body, headersNoAuth()).then(response => {
            if (response.body.status === true) {
              this.loading = false
              this.dialog = true
              this.responseData = response.body.data
              this.$store.dispatch('setCarsTimeOut', false)
              if (this.$store.state.carsTimeOutFunction) this.$store.dispatch('clearCarsTimeOutFunction')
              if (this.$store.state.carsSearchTimeOutFunction) this.$store.dispatch('clearCarsSearchTimeOutFunction')
            } else {
              this.loading = false
              this.snackbar = true
              this.color = 'error'
              this.text = response.body.message
            }
          }, () => {
            this.loading = false
            this.snackbar = true
            this.color = 'error'
            this.text = 'Something went wrong please try again in few minutes'
          })
        } else {
          this.snackbar = true
          this.color = 'error'
          this.text = 'Please provide a vaild mobile number'
          this.loading = false
        }
      } else {
        this.alert = true
        this.color = 'warning'
        this.text = 'Please fill all fields'
        this.loading = false
      }
    }
  },
  created () {
    const term = `car_id=${this.$store.state.carId}&search_id=${this.$store.state.carSearchId}`
    this.$http.get(showCar(term), { headers: headersNoAuth() }).then(response => {
      if (response.body.status === true) {
        this.loaded = false
        this.dataReady = true
        this.car = response.body.data
        this.car.fareInfo.forEach(fare => {
          if (fare.ChargeType === 'totalPrice') this.totalPrice = fare.Amount
        })
      } else {
        this.loaded = false
        this.dataReady = false
      }
    })
  }
}
</script>

<style>
    .remove-controls input[type='number'] {
    -moz-appearance:textfield;
    }
    .remove-controls input::-webkit-outer-spin-button,
    .remove-controls input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    .form {
        border-left: 0.1px solid #d2d2d2
    }
    @media screen and (max-width: 599px) {
        .form {
            border-top: 0.1px solid #d2d2d2
        }
    }
</style>
